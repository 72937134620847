import axios from "axios";
import { environmentConfig } from "../config/environmentConfig";

export default class MyHolidayService {
  static async checkUser(msisdn, service) {
    try {
      const response = await axios.post(
        `${environmentConfig.myHolidayService}/web/check-user`,
        {
          msisdn: msisdn,
          service: service,
        }
      );
      return response;
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async getUserInterests(msisdn, service) {
    try {
      const response = await axios.post(
        `${environmentConfig.myHolidayService}/interests/get-user-interests`,
        {
          msisdn: msisdn,
          service: service,
        }
      );
      return response;
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async upsertUserInterests(msisdn, service, interests) {
    try {
      const response = await axios.post(
        `${environmentConfig.myHolidayService}/interests/upsert-user-interests`,
        {
          msisdn: msisdn,
          service: service,
          interests: interests,
        }
      );
      return response;
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async getFlightOffers({
    originLocationCode,
    destinationLocationCode,
    departureDate,
    adults,
    children,
    nonStop,
    currencyCode,
    max,
  }) {
    const params = {
      originLocationCode,
      destinationLocationCode,
      departureDate,
      adults,
      children,
      nonStop,
      currencyCode,
      max,
    };

    try {
      const response = await axios.post(
        `${environmentConfig.myHolidayService}/amadeus/flight-offers-search`,
        params
      );
      return response;
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async getHotelOffers({
    cityCode,
    radius,
    radiusUnit,
    adults,
    checkInDate,
    checkOutDate,
    roomQuantity,
    currency,
    paymentPolicy,
    municipality,
  }) {
    const params = {
      cityCode,
      radius,
      radiusUnit,
      adults,
      checkInDate,
      checkOutDate,
      roomQuantity,
      currency,
      paymentPolicy,
      municipality,
    };

    try {
      const response = await axios.post(
        `${environmentConfig.myHolidayService}/amadeus/hotels-with-availability`,
        params
      );
      return response;
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async getFilteredAirports(search) {
    try {
      return await axios.post(
        `${environmentConfig.myHolidayService}/flights/get-filtered-airports`,
        {
          search,
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async getAvailableItinerary({ prompt }) {
    const params = {
      prompt,
    };

    try {
      const response = await axios.post(
        `${environmentConfig.myHolidayService}/itinerary/generate-itinerary`,
        params
      );
      return response;
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async updateUsername(msisdn, service, username) {
    try {
      return await axios.post(
        `${environmentConfig.myHolidayService}/user/update-username`,
        {
          msisdn: msisdn,
          service: service,
          username: username,
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }

  static async decryptLoginToken(token) {
    try {
      return await axios.post(
        `${environmentConfig.myHolidayService}/decrypt-token`,
        {
          token: token,
        }
      );
    } catch (error) {
      console.error("There was an error!", error);
      // Handle the error as needed
    }
  }
}
