<template>
  <div class="available-flights-container">
    <div class="header-navigation" @click="goHome">
      <font-awesome-icon icon="fa-solid fa-angle-left" class="close-icon" />
      <p class="back">Home</p>
    </div>

    <!-- Departure Flights -->
    <div class="departing-flights">
      <div class="departure-flights-navigation">
        <p class="flights-navigation-heading">Departure Flights</p>
        <div class="date">
          <p class="date-text">{{ formattedDepartureDate }}</p>
        </div>
      </div>
      <div class="flight-list">
        <flight-overview
          v-for="(flight, index) in visibleDepartureFlights"
          :key="index"
          :flight="flight"
        />
        <p
          class="view-toggle"
          @click="toggleDepartureFlights"
          v-if="sortedDepartureFlights.length > 2"
        >
          {{ showAllDepartureFlights ? "Show Less" : "View All" }}
        </p>
      </div>
    </div>

    <div class="divider"></div>

    <!-- Return Flights -->
    <div class="returning-flights">
      <div class="arriving-flights-navigation">
        <p class="flights-navigation-heading">Return Flights</p>
        <div class="date">
          <p class="date-text">{{ formattedReturnDate }}</p>
        </div>
      </div>
      <div class="flight-list">
        <flight-overview
          v-for="(flight, index) in visibleReturnFlights"
          :key="index"
          :flight="flight"
          :isReturnFlight="true"
        />
        <p
          class="view-toggle"
          @click="toggleReturnFlights"
          v-if="sortedReturnFlights.length > 2"
        >
          {{ showAllReturnFlights ? "Show Less" : "View All" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import FlightOverview from "../components/flights/FlightOverview.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    FlightOverview,
  },
  data() {
    return {
      showAllDepartureFlights: false,
      showAllReturnFlights: false,
    };
  },
  computed: {
    ...mapGetters(["departureFlights", "returnFlights", "selectedDates"]),

    // Filter and sort flights
    visibleDepartureFlights() {
      return this.sortedDepartureFlights.slice(
        0,
        this.showAllDepartureFlights ? this.sortedDepartureFlights.length : 2
      );
    },
    visibleReturnFlights() {
      return this.sortedReturnFlights.slice(
        0,
        this.showAllReturnFlights ? this.sortedReturnFlights.length : 2
      );
    },
    sortedDepartureFlights() {
      return this.departureFlights
        .slice()
        .sort((a, b) => this.compareTimes(a.departTime, b.departTime));
    },
    sortedReturnFlights() {
      return this.returnFlights
        .slice()
        .sort((a, b) => this.compareTimes(a.departTime, b.departTime));
    },

    // Format departure and return dates from selectedDates
    formattedDepartureDate() {
      return this.formatDate(this.selectedDates?.startDate) || "Departure Date";
    },
    formattedReturnDate() {
      return this.formatDate(this.selectedDates?.endDate) || "Return Date";
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString("en-US", {
        weekday: "short",
        year: "2-digit",
        month: "short",
        day: "numeric",
      });
    },
    toggleDepartureFlights() {
      this.showAllDepartureFlights = !this.showAllDepartureFlights;
    },
    toggleReturnFlights() {
      this.showAllReturnFlights = !this.showAllReturnFlights;
    },
    compareTimes(timeA, timeB) {
      const parseTime = (time) => {
        const [hour, minutePart] = time.split(":");
        const minute = minutePart.slice(0, 2);
        const period = minutePart.slice(-2);
        let hour24 = parseInt(hour, 10);
        if (period === "PM" && hour24 !== 12) hour24 += 12;
        if (period === "AM" && hour24 === 12) hour24 = 0;
        return new Date(0, 0, 0, hour24, minute).getTime();
      };
      return parseTime(timeA) - parseTime(timeB);
    },
    goHome() {
      this.$store.commit("clearHotels");
      this.$store.commit("clearFlights");
      this.$store.commit("clearItinerary");
      this.$router.push("/home");
    },
  },
};
</script>

<style scoped>
.available-flights-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  margin-bottom: 5rem;
}

.header-navigation {
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.75rem;
  margin-top: 1rem;
  font-weight: 700;
  color: var(--color-text-primary);
}

.departing-flights,
.returning-flights {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.departing-flights {
  margin-bottom: 1rem;
}

.departure-flights-navigation,
.arriving-flights-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-self: flex-start;
}

.flights-navigation-heading {
  margin: 1rem 0 1rem 0.75rem;
}

.date {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  background-color: var(--color-form-background);
  border-radius: 0.75rem;
}

.date-text {
  padding: 0;
  margin: 0;
}

.flight-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  width: 100%;
  gap: 0.75rem;
}

.divider {
  height: 0.1rem;
  background-color: var(--color-form-divider);
  margin: 0 1rem;
  width: 100%;
}

.view-toggle {
  color: var(--color-text-primary);
  cursor: pointer;
  text-align: center;
  font-size: 0.75rem;
}
</style>
