<template>
  <nav class="nav-bar">
    <ul class="nav-list">
      <li
        class="nav-item"
        :class="{ active: isActive('/available-flights') }"
        @click="navigate('available-flights')"
      >
        <img
          v-if="isActive('/available-flights')"
          src="@/assets/planeLight.svg"
          alt="Plane"
          class="icon"
        />
        <img v-else src="@/assets/planeDark.svg" alt="Plane" class="icon" />
        <p class="nav-heading">Flights</p>
      </li>
      <li
        class="nav-item"
        :class="{ active: isActive('/available-hotels') }"
        @click="navigate('available-hotels')"
      >
        <img
          v-if="isActive('/available-hotels')"
          src="@/assets/hotelLight.svg"
          alt="Hotel"
          class="icon"
        />
        <img v-else src="@/assets/hotelDark.svg" alt="Hotel" class="icon" />
        <p class="nav-heading">Hotels</p>
      </li>
      <li
        v-if="this.showCustomItinerary"
        class="nav-item"
        :class="{ active: isActive('/available-itinerary') }"
        @click="navigate('available-itinerary')"
      >
        <img
          v-if="isActive('/available-itinerary')"
          src="@/assets/itineraryLight.svg"
          alt="Itinerary"
          class="icon"
        />
        <img
          v-else
          src="@/assets/itineraryDark.svg"
          alt="Itinerary"
          class="icon"
        />
        <p class="nav-heading">Itinerary</p>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      showCustomItinerary: true,
    };
  },
  methods: {
    navigate(routeName) {
      this.$router.push({ name: routeName });
    },
    isActive(routePath) {
      // Check if the current path includes the routePath
      return this.$route.path === routePath;
    },
  },
  mounted() {
    this.showCustomItinerary = this.$store.getters.sendCustomItinerary;
  },
};
</script>

<style scoped>
.nav-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  background-color: var(--color-form-background);
  display: flex;
  justify-content: space-around;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  color: var(--color-text-primary); /* Default color for inactive items */
  width: 100%;
  padding: 0.5rem 0;
  gap: 0.75rem;
}

.nav-item.active {
  background-color: var(--vt-c-blue-primary); /* Active background color */
  color: var(--vt-c-white); /* Active text color */
}

.nav-item img {
  width: 1rem;
  height: 1rem;
}

.nav-heading {
  font-size: 0.75rem;
}

.icon {
  display: block;
}
</style>
