<template>
  <nav class="nav-bar">
    <ul class="nav-list">
      <li
        class="nav-item"
        :class="{ active: isActive('/home') }"
        @click="navigate('home')"
      >
        <font-awesome-icon icon="fa-solid fa-house" />
        <p class="nav-heading">Home</p>
      </li>
      <li
        class="nav-item"
        :class="{ active: isActive('/explore') }"
        @click="navigate('explore')"
      >
        <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
        <p class="nav-heading">Explore</p>
      </li>
      <li
        class="nav-item"
        :class="{ active: isActive('/profile') }"
        @click="navigate('profile')"
      >
        <font-awesome-icon icon="fa-solid fa-user" />
        <p class="nav-heading">Profile</p>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  methods: {
    navigate(routeName) {
      document.body.style.overflow = "";
      this.$router.push({ name: routeName });
    },
    isActive(routePath) {
      // Check if the current path includes the routePath
      return this.$route.path === routePath;
    },
  },
};
</script>

<style scoped>
.nav-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  background-color: var(--vt-c-blue-primary);
  display: flex;
  justify-content: space-around;
  padding: 0.75rem 0;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0rem 0rem -0.5rem 0rem;
  display: flex;
  width: 100%;
  justify-content: space-around;
  color: var(--vt-c-white);
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: var(--vt-c-divider-dark-1);
  font-size: 1rem;
}

.nav-heading {
  font-size: 0.75rem;
  margin: 0.5rem 0 0 0;
}

.nav-item.active {
  color: var(--color-button-primary);
}

.nav-item:focus,
.nav-item:active,
.nav-item:focus .font-awesome-icon,
.nav-item:active .font-awesome-icon {
  outline: none !important;
  border: none !important;
  box-shadow: none !important; /* In case the outline is caused by a box-shadow */
}

.nav-item,
.font-awesome-icon {
  -webkit-tap-highlight-color: transparent;
}

.nav-item:focus-within {
  outline: none !important;
}

/* This is to ensure icons align properly if FontAwesome is used */
.font-awesome-icon {
  display: flex;
  align-items: center;
}
</style>
