<template>
  <div class="login-container">
    <div class="logo-container">
      <img src="@/assets/goLogo.svg" alt="Logo" class="logo" />
      <img src="@/assets/myHoliday.svg" alt="myHoliday" class="myHoliday" />
    </div>
    <div class="greeting-container">
      <h1>Hello, <span class="explorer-text">explorer!</span></h1>
      <p class="subtext">
        Ready to plan your next adventure?<br />
        Access your personalized travel itineraries and start exploring the
        world, your way.
      </p>
      <h2>Let's go!</h2>
    </div>
    <!-- Phone input form -->
    <form @submit.prevent="login" class="phone-form">
      <div class="phone-input-wrapper">
        <label for="phone" class="phone-label">Phone</label>
        <div class="divider"></div>
        <!-- Vertical divider -->
        <input
          type="tel"
          id="phoneNumber"
          @input="handleInput"
          @keypress.enter="handleEnter"
          v-model="phoneNumber"
          class="phone-input"
          placeholder="012 345 6789"
          required
        />
      </div>
      <button type="submit" class="login-button" @click="handleLogin">
        Start your journey
      </button>
    </form>
  </div>
</template>

<script>
import store from "../store";
import MyHolidayService from "../services/MyHolidayService.js";

export default {
  data() {
    return {
      phoneNumber: "",
      showError: false,
      loading: false,
    };
  },
  methods: {
    handleInput() {
      this.showError = false;
    },
    handleEnter(event) {
      if (event.key === "Enter") {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (this.isValidSouthAfricanNumber()) {
        this.loading = true;
        if (this.phoneNumber.length === 10) {
          this.phoneNumber = "27" + this.phoneNumber.substring(1);
        }
        const response = await MyHolidayService.checkUser(
          this.phoneNumber,
          "myHoliday"
        );
        if (response.data.authenticated === 1) {
          await store.dispatch("login", {
            phoneNumber: response.data.msisdn,
            username: response.data.username,
          });

          // Log to check if the user is authenticated

          this.$router.push({ name: "home" });
        } else if (response.data.authenticated === 0) {
          window.location.href = response.data.urlRedirect;
        } else {
          console.log("Authentication failed!");
        }
      } else {
        this.showError = true;
        this.loading = false;
      }

      this.phoneNumber = "";
      this.loading = false;
    },
    isValidSouthAfricanNumber() {
      const saNumberRegex = /^(0|27)\d{9}$/;
      return saNumberRegex.test(this.phoneNumber);
    },
  },
};
</script>

<style scoped>
/* Main container for login */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 2rem;
}

/* Logo container */
.logo-container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-bottom: 1rem;
}

.logo {
  width: 7.5rem;
  margin-bottom: 2rem;
}

.myHoliday {
  width: 12rem;
}

/* Greeting text */
/* .greeting-container {
    margin-bottom: 2rem;
  } */

h1 {
  font-size: 1rem;
  color: var(--color-text-primary);
  font-weight: 700;
}

h2 {
  font-size: 1.3rem;
  color: var(--color-text-primary);
  font-weight: 700;
}

.explorer-text {
  color: var(--color-text-tertiary);
}

.subtext {
  font-size: 0.75rem;
  color: var(--color-text-secondary);
  margin-bottom: 2rem;
}

/* Phone form and input styling */
.phone-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Custom input wrapper for phone input */
.phone-input-wrapper {
  display: flex;
  align-items: center;
  background-color: var(--color-form-background);
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  width: 91%;
}

.phone-label {
  color: var(--color-form-label);
  font-size: 0.75rem;
  margin-right: 0.75rem;
}

.divider {
  width: 0.1rem;
  height: 1.5rem;
  background-color: var(--color-form-divider);
  margin-right: 0.75rem;
}

.phone-input {
  border: none;
  background: transparent;
  flex-grow: 1;
  font-size: 16px;
  color: var(--color-text-primary);
  outline: none;
}

.phone-input::placeholder {
  color: var(--color-form-placeholder);
  font-family: "Sora";
}

/* login button styling */
.login-button {
  width: 100%;
  padding: 0.8rem 1rem;
  background-color: var(--color-button-primary);
  color: var(--vt-c-white);
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: var(--color-button-hover);
}
</style>
