<template>
  <div class="room-picker-container">
    <div class="modal-overlay" @click="closeModal"></div>
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading">Personal Details</p>
        <div class="close-btn" @click="closeModal">
          <font-awesome-icon icon="fa-solid fa-x" class="close-icon" />
          <p class="close-text">Close</p>
        </div>
      </div>
      <div class="divider"></div>
      <div class="modal-body">
        <form class="form">
          <div class="input-wrapper">
            <label for="name" class="label">Name</label>
            <div class="vertical-divider"></div>
            <input
              @input="checkUsernameLength"
              type="text"
              id="name"
              v-model="localName"
              class="input"
              placeholder="Enter your name"
            />
          </div>
        </form>
        <!-- <form class="form">
          <div class="input-wrapper">
            <label for="email" class="label-email">Email</label>
            <div class="vertical-divider"></div>
            <input
              @input="checkEmail"
              type="email"
              id="email"
              v-model="email"
              class="input"
              placeholder="janedoe@gmail.com"
            />
          </div>
        </form> -->
      </div>
      <p v-if="showError" class="error-message">
        Usernames are limited to 15 characters.
      </p>
      <p v-if="showEmailError" class="error-message">
        Please enter a valid email address.
      </p>
      <div class="modal-footer">
        <button @click="saveChanges" class="btn-done">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import MyHolidayService from "../../services/MyHolidayService";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localName: this.name, // Local copy of the name
      email: "", // Email input
      showError: false, // Username error flag
      showEmailError: false, // Email error flag
    };
  },
  methods: {
    closeModal() {
      // Close modal without updating the username
      this.$emit("close-modal");
    },
    saveChanges() {
      // Check username restrictions
      this.checkUsernameLength();
      // Check email validation
      this.checkEmail();
      // Only emit if there are no errors
      if (!this.showError && !this.showEmailError) {
        // Emit the updated name and email only when saving
        this.$emit("update-name", this.localName); // Emit updated name
        this.$emit("apply-choices", {
          name: this.localName,
          email: this.email, // Email can be empty
        });
        this.$store.dispatch("updateUsername", this.localName);
        this.saveUsername();
        this.closeModal(); // Close the modal after saving
      }
    },
    checkUsernameLength() {
      if (this.localName.length > 15) {
        this.localName = this.localName.substr(0, 15);
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
    checkEmail() {
      // If email is provided, validate its format
      if (this.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)) {
        this.showEmailError = true;
      } else {
        this.showEmailError = false;
      }
    },
    async saveUsername() {
      const msisdn = this.$store.getters.msisdn;
      this.isEditing = false;
      await MyHolidayService.updateUsername(
        msisdn,
        "myHoliday",
        this.localName
      );
    },
  },
  watch: {
    // Watch for changes to the name prop and update localName accordingly
    name(newVal) {
      this.localName = newVal;
    },
  },
};
</script>

<style scoped>
.room-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;
  background-color: rgba(3, 34, 58, 0.8);
  max-width: 480px;
}

.modal-overlay {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: relative;
}

.modal-content {
  position: absolute;
  bottom: 0;
  background-color: var(--vt-c-white);
  border-radius: 2rem 2rem 0 0;
  z-index: 1001;
  width: 100%;
  max-width: 480px;
  padding-bottom: 10rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 1rem 1rem;
}

.heading {
  color: var(--color-text-primary);
  font-weight: 700;
  font-size: 0.75rem;
}

.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 1rem;
  background-color: var(--color-form-background);
  border-radius: 0.75rem;
  color: var(--color-text-primary);
  font-size: 0.6rem;
}

.modal-body {
  margin: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.input-wrapper {
  display: flex;
  align-items: center;
  background-color: var(--color-form-background);
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  width: 91%;
}

.label {
  color: var(--color-form-label);
  font-size: 0.75rem;
  margin-right: 0.75rem;
}

.label-email {
  color: var(--color-form-label);
  font-size: 0.75rem;
  margin-right: 1rem;
}

.vertical-divider {
  width: 0.1rem;
  height: 1.5rem;
  background-color: var(--color-form-divider);
  margin-right: 0.75rem;
}

.input {
  border: none;
  background: transparent;
  flex-grow: 1;
  font-size: 16px;
  color: var(--color-text-primary);
  outline: none;
}

.input::placeholder {
  color: var(--color-form-placeholder);
  font-family: "Sora";
}

.modal-footer {
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 1rem;
}

.btn-done {
  background-color: var(--vt-c-blue-secondary);
  color: var(--vt-c-white);
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 1.3rem 2rem;
  font-size: 0.75rem;
  font: "Sora";
  font-weight: 600;
  border-radius: 0.5rem;
}

.divider {
  height: 0.1rem;
  background-color: var(--color-form-divider);
  margin: 0 1rem;
}

.error-message {
  color: var(--color-text-primary);
  font-size: 0.75rem;
  margin-top: 1.5rem;
  text-align: center;
}
</style>
