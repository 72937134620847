<template>
  <div class="available-hotels-container">
    <div class="header-navigation" @click="goHome">
      <font-awesome-icon icon="fa-solid fa-angle-left" class="close-icon" />
      <p class="back">Home</p>
    </div>
    <div class="hotels">
      <div class="hotels-navigation">
        <p class="hotels-navigation-heading">Check-In Date</p>
        <div class="date">
          <p class="date-text">{{ formattedCheckInDate }}</p>
        </div>
      </div>
      <div class="hotel-list">
        <hotel-overview
          v-for="hotel in visibleHotels"
          :key="hotel.hotelId"
          :hotel="hotel"
        />
        <p
          class="view-toggle"
          @click="toggleHotels"
          v-if="sortedHotels.length > 2"
        >
          {{ showAllHotels ? "Show Less" : "View All" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HotelOverview from "../components/hotels/HotelOverview.vue";

export default {
  components: {
    HotelOverview,
  },
  data() {
    return {
      showAllHotels: false,
    };
  },
  computed: {
    ...mapState({
      hotels: (state) => state.availableHotels, // Fetch hotels from the Vuex store
      startDate: (state) => state.startDate,
    }),
    visibleHotels() {
      // Show only the first 2 hotels unless 'showAllHotels' is true
      return this.sortedHotels.slice(
        0,
        this.showAllHotels ? this.sortedHotels.length : 2
      );
    },
    sortedHotels() {
      // Sort hotels by price in ascending order
      return this.hotels
        .slice()
        .sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    },
    formattedCheckInDate() {
      // Format the startDate into a more readable format (e.g., 21 Aug 2024)
      if (this.startDate) {
        return new Date(this.startDate).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      }
      return "N/A"; // Default if no start date is available
    },
  },
  methods: {
    toggleHotels() {
      this.showAllHotels = !this.showAllHotels;
    },
    goHome() {
      this.$router.push("/home");
      this.$store.commit("clearHotels");
      this.$store.commit("clearFlights");
      this.$store.commit("clearItinerary");
    },
  },
};
</script>

<style scoped>
.available-hotels-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  margin-bottom: 5rem;
}

.header-navigation {
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.75rem;
  margin-top: 1rem;
  font-weight: 700;
  color: var(--color-text-primary);
}

.hotels {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.hotels-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-self: flex-start;
}

.hotels-navigation-heading {
  margin: 1rem 0 1rem 0.75rem;
}

.date {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  background-color: var(--color-form-background);
  border-radius: 0.75rem;
}

.date-text {
  padding: 0;
  margin: 0;
}

.hotel-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  width: 100%;
  gap: 0.75rem;
}

.view-toggle {
  color: var(--color-text-primary);
  cursor: pointer;
  text-align: center;
  font-size: 0.75rem;
}
</style>
