<template>
  <div class="explore-container">
    <div class="header">
      <p class="intro">
        Explore popular cities that are frequently visited by people with
        similar interests to yours.
      </p>
      <p class="heading">Popular Countries</p>
      <div
        :class="['selector', { 'selector-active': isDropdownActive }]"
        @click="toggleDropdown"
      >
        <!-- Safely access selectedCountry.name and provide fallback -->
        <p class="country">
          {{ selectedCountry ? selectedCountry.name : "Select Country" }}
        </p>
        <font-awesome-icon
          v-if="!isDropdownActive"
          icon="fa-solid fa-angle-down"
          class="arrow-icon"
        />
        <font-awesome-icon
          v-else
          icon="fa-solid fa-angle-up"
          class="arrow-icon"
        />
      </div>
      <div
        v-if="isDropdownActive"
        :class="['dropdown', { 'dropdown-active': isDropdownActive }]"
      >
        <ul>
          <li
            v-for="country in countries"
            :key="country.name"
            @click="selectCountry(country)"
          >
            {{ country.name }}
          </li>
        </ul>
      </div>
      <p class="result-header">Top Cities</p>
      <div class="divider"></div>
    </div>

    <!-- City List, displayed only if a country is selected -->
    <div v-if="selectedCountry" class="city-list">
      <div v-for="(city, index) in selectedCountry.cities" :key="city.name">
        <city-overview :city="city" :cityImage="city.image" />
        <!-- Show divider if it's not the last city -->
        <div
          v-if="index !== selectedCountry.cities.length - 1"
          class="city-divider"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import CityOverview from "../components/itinerary/CityOverview.vue";

// Importing images for South Africa
import capeTown from "../assets/capeTown.jpg";
import durban from "../assets/durban.jpg";
import johannesburg from "../assets/johannesburg.jpg";
import pretoria from "../assets/pretoria.jpg";
import bloemfontein from "../assets/bloemfontein.jpg";

// // Importing images for USA
import newYork from "../assets/newYork.jpg";
import losAngeles from "../assets/losAngeles.jpg";
import chicago from "../assets/chicago.jpg";
import sanFrancisco from "../assets/sanFrancisco.jpg";
import miami from "../assets/miami.jpg";

// // Importing images for Italy
import rome from "../assets/rome.jpg";
import florence from "../assets/florence.jpg";
import venice from "../assets/venice.jpg";
import milan from "../assets/milan.jpg";
import naples from "../assets/naples.jpg";

// // Importing images for Japan
import tokyo from "../assets/tokyo.jpg";
import kyoto from "../assets/kyoto.jpg";
import osaka from "../assets/osaka.jpg";
import hiroshima from "../assets/hiroshima.jpg";
import nagoya from "../assets/nagoya.jpg";

// // Importing images for France
import paris from "../assets/paris.jpg";
import nice from "../assets/nice.jpg";
import lyon from "../assets/lyon.jpg";
import marseille from "../assets/marseille.jpg";
import bordeaux from "../assets/bordeaux.jpg";

export default {
  components: {
    CityOverview,
  },
  data() {
    return {
      isDropdownActive: false,
      countries: [
        {
          name: "South Africa",
          cities: [
            {
              name: "Cape Town",
              image: capeTown,
              attractions: [
                "Table Mountain",
                "Robben Island",
                "V&A Waterfront",
                "Cape Point",
                "Kirstenbosch National Botanical Garden",
              ],
            },
            {
              name: "Durban",
              image: durban,
              attractions: [
                "uShaka Marine World",
                "Golden Mile",
                "Moses Mabhida Stadium",
                "Valley of a Thousand Hills",
                "Botanic Gardens",
              ],
            },
            {
              name: "Johannesburg",
              image: johannesburg,
              attractions: [
                "Apartheid Museum",
                "Gold Reef City",
                "Constitution Hill",
                "Soweto",
                "Lion Park",
              ],
            },
            {
              name: "Pretoria",
              image: pretoria,
              attractions: [
                "Union Buildings",
                "Voortrekker Monument",
                "Freedom Park",
                "National Zoological Gardens",
                "Groenkloof Nature Reserve",
              ],
            },
            {
              name: "Bloemfontein",
              image: bloemfontein,
              attractions: [
                "Naval Hill",
                "Franklin Game Reserve",
                "Oliewenhuis Art Museum",
                "Fourth Raadsaal",
                "National Museum",
              ],
            },
          ],
        },
        {
          name: "USA",
          cities: [
            {
              name: "New York",
              image: newYork,
              attractions: [
                "Statue of Liberty",
                "Central Park",
                "Empire State Building",
                "Times Square",
                "Metropolitan Museum of Art",
              ],
            },
            {
              name: "Los Angeles",
              image: losAngeles,
              attractions: [
                "Hollywood Sign",
                "Venice Beach",
                "Griffith Observatory",
                "Getty Center",
                "Santa Monica Pier",
              ],
            },
            {
              name: "Chicago",
              image: chicago,
              attractions: [
                "Millennium Park",
                "Art Institute of Chicago",
                "Navy Pier",
                "Willis Tower",
                "Lincoln Park Zoo",
              ],
            },
            {
              name: "San Francisco",
              image: sanFrancisco,
              attractions: [
                "Golden Gate Bridge",
                "Alcatraz Island",
                "Fisherman's Wharf",
                "Chinatown",
                "Lombard Street",
              ],
            },
            {
              name: "Miami",
              image: miami,
              attractions: [
                "South Beach",
                "Ocean Drive",
                "Miami Seaquarium",
                "Vizcaya Museum",
                "Little Havana",
              ],
            },
          ],
        },
        {
          name: "Italy",
          cities: [
            {
              name: "Rome",
              image: rome,
              attractions: [
                "Colosseum",
                "Vatican Museums",
                "Trevi Fountain",
                "St. Peter's Basilica",
                "Roman Forum",
              ],
            },
            {
              name: "Florence",
              image: florence,
              attractions: [
                "Uffizi Gallery",
                "Florence Cathedral (Duomo)",
                "Ponte Vecchio",
                "Piazza della Signoria",
                "Pitti Palace",
              ],
            },
            {
              name: "Venice",
              image: venice,
              attractions: [
                "St. Mark's Basilica",
                "Grand Canal",
                "Rialto Bridge",
                "Doge's Palace",
                "St. Mark's Square",
              ],
            },
            {
              name: "Milan",
              image: milan,
              attractions: [
                "Milan Cathedral (Duomo di Milano)",
                "Sforza Castle",
                "Galleria Vittorio Emanuele II",
                "Teatro alla Scala",
                "Santa Maria delle Grazie (The Last Supper)",
              ],
            },
            {
              name: "Naples",
              image: naples,
              attractions: [
                "Pompeii Archaeological Park",
                "Mount Vesuvius",
                "Naples National Archaeological Museum",
                "Castel dell'Ovo",
                "Piazza del Plebiscito",
              ],
            },
          ],
        },
        {
          name: "Japan",
          cities: [
            {
              name: "Tokyo",
              image: tokyo,
              attractions: [
                "Tokyo Tower",
                "Meiji Shrine",
                "Shibuya Crossing",
                "Senso-ji Temple",
                "Ueno Park",
              ],
            },
            {
              name: "Kyoto",
              image: kyoto,
              attractions: [
                "Fushimi Inari Shrine",
                "Kinkaku-ji (Golden Pavilion)",
                "Arashiyama Bamboo Grove",
                "Nijo Castle",
                "Gion District",
              ],
            },
            {
              name: "Osaka",
              image: osaka,
              attractions: [
                "Osaka Castle",
                "Dotonbori",
                "Universal Studios Japan",
                "Umeda Sky Building",
                "Shitenno-ji Temple",
              ],
            },
            {
              name: "Hiroshima",
              image: hiroshima,
              attractions: [
                "Hiroshima Peace Memorial Park",
                "Miyajima Island",
                "Hiroshima Castle",
                "Shukkei-en Garden",
                "Atomic Bomb Dome",
              ],
            },
            {
              name: "Nagoya",
              image: nagoya,
              attractions: [
                "Nagoya Castle",
                "Atsuta Shrine",
                "Osu Shopping Street",
                "SCMAGLEV and Railway Park",
                "Higashiyama Zoo and Botanical Gardens",
              ],
            },
          ],
        },
        {
          name: "France",
          cities: [
            {
              name: "Paris",
              image: paris,
              attractions: [
                "Eiffel Tower",
                "Louvre Museum",
                "Notre-Dame Cathedral",
                "Champs-Élysées",
                "Montmartre and Sacré-Cœur Basilica",
              ],
            },
            {
              name: "Nice",
              image: nice,
              attractions: [
                "Promenade des Anglais",
                "Castle Hill",
                "Musée Matisse",
                "Old Town (Vieux Nice)",
                "Place Masséna",
              ],
            },
            {
              name: "Lyon",
              image: lyon,
              attractions: [
                "Basilica of Notre-Dame de Fourvière",
                "Vieux Lyon (Old Lyon)",
                "Parc de la Tête d'Or",
                "Museum of Fine Arts of Lyon",
                "Presqu'île District",
              ],
            },
            {
              name: "Marseille",
              image: marseille,
              attractions: [
                "Old Port (Vieux-Port)",
                "Basilique Notre-Dame de la Garde",
                "Château d'If",
                "Calanques National Park",
                "MuCEM (Museum of European and Mediterranean Civilizations)",
              ],
            },
            {
              name: "Bordeaux",
              image: bordeaux,
              attractions: [
                "Place de la Bourse",
                "Cité du Vin",
                "Bordeaux Cathedral (Saint-André)",
                "Grosse Cloche",
                "Jardin Public",
              ],
            },
          ],
        },
      ],
      selectedCountry: null, // Initialize null and set in mounted hook
    };
  },
  mounted() {
    // Default to South Africa
    this.setSelectedCountry(this.$route.query.country);
  },
  watch: {
    "$route.query.country": function (newCountry) {
      this.setSelectedCountry(newCountry);
    },
  },
  methods: {
    setSelectedCountry(countryName) {
      if (countryName) {
        this.selectedCountry = this.countries.find(
          (country) => country.name.toLowerCase() === countryName.toLowerCase()
        );
      } else {
        // Default to South Africa
        this.selectedCountry = this.countries.find(
          (country) => country.name === "South Africa"
        );
      }
    },
    toggleDropdown() {
      this.isDropdownActive = !this.isDropdownActive;
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.isDropdownActive = false;
    },
  },
};
</script>

<style scoped>
.explore-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  margin-bottom: 5rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 1rem;
}

.intro {
  font-size: 0.75rem;
  color: var(--color-text-secondary);
  margin-bottom: 0.75rem;
  margin-top: 0;
}

.heading {
  font-size: 0.75rem;
  color: var(--color-text-primary);
  margin: 0.5rem 0 0.5rem 0.75rem;
}

.selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
  background-color: var(--color-form-background);
  color: var(--color-text-primary);
  box-sizing: border-box;
  font-size: 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: border-radius 0.2s ease;
}

.selector-active {
  border-radius: 0.5rem 0.5rem 0 0;
}

.arrow-icon {
  margin-left: 1rem;
}

.dropdown {
  background-color: #fbfbfb;
  border-radius: 0 0 0.5rem 0.5rem;
  width: 100%;
  margin-top: 0;
  font-size: 0.75rem;
  max-height: 12rem;
  overflow-y: auto;
  transition: border-radius 0.2s ease;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dropdown::-webkit-scrollbar {
  display: none;
}

.dropdown-active {
  border-radius: 0 0 0.5rem 0.5rem;
}

.dropdown ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: var(--color-form-divider);
}

.result-header {
  font-size: 0.75rem;
  color: var(--color-text-primary);
  margin: 1.5rem 0 0.75rem 0.75rem;
}

.city-list {
  width: 100%;
  margin: 0;
}

.divider {
  height: 0.1rem;
  width: 100%;
  background-color: var(--color-form-divider);
}

.city-divider {
  height: 0.1rem;
  width: 100%;
  background-color: var(--color-form-divider);
  margin-top: 1rem;
}

.city-overview {
  margin-bottom: 1rem;
}
</style>
