// store.js
import { createStore } from "vuex";
// import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    isAuthenticated: false,
    msisdn: "",
    username: "",
    sendCustomItinerary: true,
    startDate: null,
    endDate: null,
    departureFlights: [],
    returnFlights: [],
    selectedRooms: 0,
    selectedAdults: 0,
    selectedChildren: 0,
    selectedMunicipality: "", // New state property for storing the selected municipality
    availableHotels: [], // New state property for storing hotel offers
    availableItinerary: [],
    travelInterests: [],
    userInterests: [],
  },
  mutations: {
    login(state) {
      state.isAuthenticated = true;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.msisdn = "";
      state.username = "";
    },
    setMsisdn(state, msisdn) {
      state.msisdn = msisdn;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setDepartureFlights(state, flights) {
      state.departureFlights = flights;
    },
    setReturnFlights(state, flights) {
      state.returnFlights = flights;
    },
    setDates(state, { startDate, endDate }) {
      state.startDate = startDate;
      state.endDate = endDate;
    },
    clearDates(state) {
      state.startDate = null;
      state.endDate = null;
    },
    clearFlights(state) {
      state.departureFlights = [];
      state.returnFlights = [];
    },
    setGuests(state, { rooms, adults, children }) {
      state.selectedRooms = rooms;
      state.selectedAdults = adults;
      state.selectedChildren = children;
    },
    clearGuests(state) {
      state.selectedRooms = 0;
      state.selectedAdults = 0;
      state.selectedChildren = 0;
    },
    setMunicipality(state, municipality) {
      state.selectedMunicipality = municipality; // Mutation to store selected municipality
    },
    setAvailableHotels(state, hotels) {
      state.availableHotels = hotels; // Mutation to store available hotel offers
    },
    clearHotels(state) {
      state.availableHotels = []; // Mutation to clear hotel data
    },
    setAvailableItinerary(state, itinerary) {
      state.availableItinerary = itinerary;
    },
    clearItinerary(state) {
      state.availableItinerary = [];
    },
    setTravelInterests(state, interests) {
      state.travelInterests = interests;
    },
    setUserInterests(state, interests) {
      state.userInterests = interests;
    },
    setSendCustomItinerary(state, value) {
      state.sendCustomItinerary = value;
    },
  },
  actions: {
    login({ commit }, payload) {
      commit("login");
      commit("setMsisdn", payload.phoneNumber);
      commit("setUsername", payload.username);
    },
    logout({ commit }) {
      commit("logout");
    },
    updateUsername({ commit }, username) {
      commit("setUsername", username);
    },
    saveDepartureFlights({ commit }, flights) {
      commit("setDepartureFlights", flights);
    },
    saveReturnFlights({ commit }, flights) {
      commit("setReturnFlights", flights);
    },
    saveDates({ commit }, dates) {
      commit("setDates", dates);
    },
    clearAllDates({ commit }) {
      commit("clearDates");
    },
    clearAllFlights({ commit }) {
      commit("clearFlights");
    },
    saveGuests({ commit }, guests) {
      commit("setGuests", guests);
    },
    clearAllGuests({ commit }) {
      commit("clearGuests");
    },
    saveMunicipality({ commit }, municipality) {
      commit("setMunicipality", municipality); // Action to save selected municipality
    },
    saveAvailableHotels({ commit }, hotels) {
      commit("setAvailableHotels", hotels); // Action to save available hotel offers
    },
    clearAllHotels({ commit }) {
      commit("clearHotels"); // Action to clear hotel data
    },
    saveAvailableItinerary({ commit }, itinerary) {
      commit("setAvailableItinerary", itinerary); // Action to save available hotel offers
    },
    clearAllItinerary({ commit }) {
      commit("clearItinerary"); // Action to clear hotel data
    },
    saveTavelInterests({ commit }, interests) {
      commit("setTravelInterests", interests); // Action to save available hotel offers
    },
    saveUserInterests({ commit }, interests) {
      commit("setUserInterests", interests); // Action to save available hotel offers
    },
    saveSendCustomItinerary({ commit }, value) {
      commit("setSendCustomItinerary", value); // Action to save available hotel offers
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    msisdn: (state) => state.msisdn,
    username: (state) => state.username,
    selectedDates: (state) => ({
      startDate: state.startDate,
      endDate: state.endDate,
    }),
    departureFlights: (state) => state.departureFlights,
    returnFlights: (state) => state.returnFlights,
    selectedRooms: (state) => state.selectedRooms,
    selectedAdults: (state) => state.selectedAdults,
    selectedChildren: (state) => state.selectedChildren,
    selectedMunicipality: (state) => state.selectedMunicipality, // Getter for selected municipality
    availableHotels: (state) => state.availableHotels, // Getter for available hotels
    availableItinerary: (state) => state.availableItinerary,
    travelInterests: (state) => state.travelInterests,
    userInterests: (state) => state.userInterests,
    sendCustomItinerary: (state) => state.sendCustomItinerary,
  },
  // plugins: [
  //   createPersistedState({
  //     key: "myHolidayStore", // Set a specific key for the session storage
  //     storage: window.sessionStorage,
  //     paths: ["msisdn", "isAuthenticated"], // Persist only relevant state
  //   }),
  // ],
});
