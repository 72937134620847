import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";

import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import ExploreView from "../views/ExploreView.vue";
import ProfileView from "../views/ProfileView.vue";
import AvailableFlights from "../views/AvailableFlights.vue";
import AvailableHotels from "../views/AvailableHotels.vue";
import AvailableItinerary from "../views/AvailableItinerary.vue";
import SavedItineraries from "../views/SavedItineraries.vue";
import TokenHandler from "../views/TokenHandler.vue";
import TermsView from "../views/TermsView.vue";

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes: [
    { path: "/", redirect: "/login" },
    {
      path: "/login",
      name: "login",
      component: LoginView,
      meta: { hideNavbar: true, hideSearchResultsNav: true },
    },
    {
      path: "/home",
      name: "home",
      component: HomeView,
      meta: { hideSearchResultsNav: true },
    },
    {
      path: "/explore",
      name: "explore",
      component: ExploreView,
      meta: { hideSearchResultsNav: true },
    },
    {
      path: "/profile",
      name: "profile",
      component: ProfileView,
      meta: { hideSearchResultsNav: true },
    },
    {
      path: "/available-flights",
      name: "available-flights",
      component: AvailableFlights,
      meta: { hideNavbar: true },
    },
    {
      path: "/available-hotels",
      name: "available-hotels",
      component: AvailableHotels,
      meta: { hideNavbar: true },
    },
    {
      path: "/available-itinerary",
      name: "available-itinerary",
      component: AvailableItinerary,
      meta: { hideNavbar: true },
    },
    {
      path: "/saved-itineraries",
      name: "saved-itineraries",
      component: SavedItineraries,
      meta: { hideSearchResultsNav: true },
    },
    {
      path: "/token",
      name: "tokenHandler",
      component: TokenHandler, // You'll need to create this component
      meta: { hideNavbar: true, hideSearchResultsNav: true },
      beforeEnter: (to, from, next) => {
        // Optional: Add logic here to verify the token format before proceeding
        next();
      },
    },
    {
      path: "/terms",
      name: "terms",
      component: TermsView,
      meta: { hideSearchResultsNav: true },
    },
  ],
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  // Log the current authentication state

  if (
    to.name === "login" ||
    to.path === "/terms" ||
    to.name === "tokenHandler"
  ) {
    next();
  } else if (!store.getters.isAuthenticated) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
