<template>
  <div class="hotel-overview-container">
    <div class="header">
      <p class="hotel">{{ hotel.name }}</p>
      <div class="hotel-rank" v-if="hotel.rating && hotel.rating >= 0">
        <font-awesome-icon
          v-for="n in Math.floor(hotel.rating)"
          :key="n"
          icon="fa-solid fa-star"
          class="star-icon"
        />
        <font-awesome-icon
          v-if="hotel.rating % 1 !== 0"
          icon="fa-solid fa-star-half-stroke"
          class="star-icon"
        />
        <font-awesome-icon
          v-for="n in 5 - Math.ceil(hotel.rating)"
          :key="n"
          icon="fa-regular fa-star"
          class="star-icon"
        />
      </div>
    </div>
    <div class="hotel-details">
      <div class="room">
        <p class="room-heading">Room Type</p>
        <p class="room-type">{{ formattedRoomType }}</p>
        <!-- Use formatted room type -->
      </div>
      <div class="location">
        <p class="location-heading">Location</p>
        <p class="location-address">{{ hotel.municipality }}</p>
      </div>
      <div class="hotel-price">
        <p class="price">R{{ hotel.price }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hotel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedRoomType() {
      if (!this.hotel.roomType) return "";
      return this.hotel.roomType
        .toLowerCase() // Convert to lowercase first
        .split("_") // Split by underscore
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(" "); // Join words with space
    },
  },
};
</script>

<style scoped>
.hotel-overview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-form-background);
  max-width: 480px;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width */
  border-radius: 0.75rem 0.75rem 0 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--vt-c-blue-primary);
  padding: 0 0.75rem;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width */
  color: var(--vt-c-white);
  font-size: 0.75rem;
  border-radius: 0.5rem;
}

.hotel {
  font-weight: 600;
}

.hotel-details {
  font-display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.75rem;
  margin-bottom: 1rem;
}

.star-icon {
  color: #ffd700;
  margin-right: 0.2rem;
}

.room {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: 0.75rem;
  margin-top: 1rem;
  color: var(--vt-c-blue-primary);
}

.room-heading {
  margin: 0;
  padding: 0;
  font-weight: 700;
}

.room-type {
  margin: 0;
  padding: 0;
}

.location {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: 0.75rem;
  margin-top: 1rem;
  color: var(--vt-c-blue-primary);
}

.location-heading {
  margin: 0;
  padding: 0;
  font-weight: 700;
}

.location-address {
  margin: 0;
  padding: 0;
}

.hotel-price {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: 1rem;
  margin-top: 1rem;
  color: var(--vt-c-green-primary);
}

.price {
  margin: 0;
  padding: 0;
  font-weight: 700;
}
</style>
