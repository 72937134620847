<template>
  <div class="flight-overview-container">
    <div class="header" :class="{ 'return-flight-header': isReturnFlight }">
      <p class="airline">{{ flight.airline }}</p>
      <p class="flight-number">{{ flight.flightNumber }}</p>
    </div>
    <div class="flight-details">
      <div class="airports">
        <div class="departure-airport">
          <p class="airport-code">{{ flight.departureAirportCode }}</p>
          <p class="airport-name">{{ flight.departureAirportName }}</p>
        </div>
        <div class="arrival-airport">
          <p class="airport-code">{{ flight.arrivalAirportCode }}</p>
          <p class="airport-name">{{ flight.arrivalAirportName }}</p>
        </div>
      </div>
      <div class="flight-icons">
        <img src="@/assets/flyingPlane.svg" alt="Plane" class="plane-icon" />
      </div>
      <div class="dates-times">
        <div class="departure-times">
          <p class="depart">DEPART</p>
          <p class="date">{{ flight.departDate }}</p>
          <p class="time">{{ flight.departTime }}</p>
        </div>
        <div class="time-price">
          <p class="flight-time">{{ flight.flightDuration }}</p>
          <p class="price">{{ flight.price }}</p>
        </div>
        <div class="arrival-times">
          <p class="depart">ARRIVE</p>
          <p class="date">{{ flight.arriveDate }}</p>
          <p class="time">{{ flight.arriveTime }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isReturnFlight: {
      type: Boolean,
      default: false,
    },
    flight: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.flight-overview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-form-background);
  max-width: 480px;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width */
  border-radius: 0.75rem 0.75rem 0 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--vt-c-blue-secondary);
  padding: 0 0.75rem;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width */
  color: var(--vt-c-white);
  font-size: 0.75rem;
  border-radius: 0.5rem;
}

.return-flight-header {
  background-color: var(
    --vt-c-orange-primary
  ); /* Orange background for return flights */
}

.airline {
  font-weight: 600;
}

.flight-details {
  font-display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.75rem;
  margin-bottom: 1rem;
}

.airports {
  display: flex;
  align-items: start;
  justify-content: space-between;
  color: var(--color-text-primary);
  margin-bottom: 1rem;
}

.departure-airport {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0;
}

.airrival-airport {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  gap: 0;
  text-align: right;
}

.aiport-code {
  font-size: 0.75rem;
  font-weight: 700;
  margin: 1rem 0 0.5rem 0;
}

.airport-name {
  font-size: 0.5rem;
  margin: 0;
}

.flight-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 0.5rem 0;
}

.plane-icon {
  width: 100%;
}

.dates-times {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin: 0;
}

.departure-times {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0;
  color: var(--color-text-primary);
}

.depart,
.date,
.time {
  margin: 0;
  color: var(--color-text-primary);
}

.depart {
  font-size: 0.75rem;
}

.date,
.time {
  font-size: 0.5rem;
}

.arrival-times {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: right;
  color: var(--color-text-primary);
}

.time-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.flight-time {
  font-size: 0.5rem;
  margin: 0;
  color: var(--color-form-label);
}

.price {
  margin: 0;
  color: var(--vt-c-green-primary);
  font-weight: 800;
  font-size: 0.75rem;
}
</style>
