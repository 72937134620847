<template>
  <div class="terms-container">
    <h2>myHoliday.ai T&CS</h2>
    <h3>Terms and Conditions</h3>
    <ol>
      <li>
        <span class="title">INTRODUCTION</span>
        <ol>
          <li class="paragraph">
            These terms and conditions (hereinafter referred to as the
            “Agreement”) govern your use of the myHoliday.ai service (the
            “Service”).
          </li>
          <li class="paragraph">
            The Agreement is entered into by you, the user of the Service
            (hereinafter referred to as “You”), and the provider of the Service,
            Hulala Holdings (Proprietary) Limited (hereinafter referred to as
            “Us,” “We,” or the “Company”).
          </li>
          <li class="paragraph">
            By using the Service, You agree to abide by this Agreement. If You
            do not have the bill payers’ permission to use the Service, please
            do not access the Service.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">THE SERVICE</span>
        <ol>
          <li class="paragraph">
            myHoliday.ai gives You access to exclusive travel deals, including
            discounts on flights, hotels, and data usage.
          </li>
          <li class="paragraph">
            Customers can get up to 80% off on mobile data while traveling when
            they have a fully paid-up account.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">HOW TO SUBSCRIBE</span>
        <ol>
          <li class="paragraph">
            To subscribe, You can:
            <ol>
              <li class="sub-paragraph">
                Visit www.myholiday.ai and click ‘Join Now’,
              </li>
              <li class="sub-paragraph">
                Dial *123# and follow the prompts to subscribe.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">WHAT IT COSTS</span>
        <ol>
          <li class="paragraph">
            The Service costs R7.00 per day, R35.00 per week, or R60.00 per
            month, depending on the option you choose (Network charges may also
            apply).
          </li>
          <li class="paragraph">
            The subscription fee will be charged directly to Your mobile network
            account via airtime or billing. By subscribing, You authorize Us to
            deduct the relevant amounts directly from Your network provider, and
            You confirm You have the necessary permissions from the bill payer.
          </li>
          <li class="paragraph">
            Data deals (up to 80% off) are accessible only if Your account is
            fully paid up and active.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">USING THE SERVICE</span>
        <ol>
          <li class="paragraph">
            As a subscriber, You gain access to various travel deals, including
            discounts on flights, hotels, and data services.
          </li>
          <li class="paragraph">
            Deals and offers may be subject to availability, and We do not
            guarantee the availability of any specific travel option at all
            times.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">HOW TO UNSUBSCRIBE</span>
        <ol>
          <li class="paragraph">
            You can unsubscribe at any time by dialing *155# and selecting the
            “Unsubscribe” option.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">WHO MAY USE THIS SERVICE</span>
        <ol>
          <li class="paragraph">
            To subscribe, You must:
            <ol>
              <li class="sub-paragraph">Be located in South Africa;</li>
              <li class="sub-paragraph">Be 18 years or older;</li>
              <li class="sub-paragraph">
                Be a customer of a participating mobile network;
              </li>
              <li class="sub-paragraph">
                Have an active mobile data connection or Wi-Fi.
              </li>
            </ol>
          </li>
          <li class="paragraph">
            You agree to provide accurate and up-to-date information and comply
            with all applicable laws and regulations when using the Service.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">OUR LIABILITY</span>
        <ol>
          <li class="paragraph">
            We are not liable for any losses or damages that may arise from Your
            use of the Service, except as specified by law.
          </li>
          <li class="paragraph">
            Our total liability to You, for any reason, is limited to the amount
            paid for the Service in the previous 12 months.
          </li>
          <li class="paragraph">
            We are not responsible for any loss or damage caused by
            circumstances beyond our control, including network outages, service
            disruptions, or third-party actions.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">SERVICE DISRUPTION OR TERMINATION</span>
        <ol>
          <li class="paragraph">
            We reserve the right to suspend or terminate Your subscription if
            You fail to pay the subscription fees or violate this Agreement.
          </li>
          <li class="paragraph">
            We may also suspend or discontinue the Service temporarily or
            permanently without prior notice, and We will not be liable for any
            loss or inconvenience caused.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">CHANGES TO THIS AGREEMENT</span>
        <ol>
          <li class="paragraph">
            We reserve the right to modify this Agreement at any time. If
            significant changes are made, We will endeavor to notify You via SMS
            or email.
          </li>
          <li class="paragraph">
            By continuing to use the Service after changes are made, You accept
            the revised terms.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">INTELLECTUAL PROPERTY</span>
        <ol>
          <li class="paragraph">
            All content, trademarks, and data on the Service, including but not
            limited to text, graphics, logos, and software, are owned by Us or
            licensed to Us.
          </li>
          <li class="paragraph">
            You may not copy, distribute, or use any content from the Service
            for commercial purposes without Our prior written consent.
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">CONTACT US</span>
        <ol>
          <li class="paragraph">
            If You have any questions or concerns regarding billing or the
            Service, please contact Us at
            <a href="mailto:support@zaply.co.za">support@zaply.co.za</a>
          </li>
        </ol>
      </li>
      <div class="spacer"></div>
      <li>
        <span class="title">GENERAL</span>
        <ol>
          <li class="paragraph">
            This Agreement shall be governed by and construed in accordance with
            the laws of South Africa. Any disputes arising from or related to
            the Service will be subject to the jurisdiction of South African
            courts.
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<style scoped>
.terms-container {
  margin: 0rem 0rem 0rem 0rem;
  padding: 1rem 1rem 5rem 1rem;
  color: var(--color-text-primary);
}

ol {
  counter-reset: section;
  list-style-type: none;
  padding: 0;
}

ol > li {
  counter-increment: section;
}

ol > li:before {
  content: counters(section, ".") ". ";
}

ol > li > ol {
  counter-reset: subsection;
}

ol > li > ol > li {
  counter-increment: subsection;
  padding-left: 1rem;
}

ol > li > ol > li:before {
  content: counters(section, ".") "." counters(subsection, ".") " ";
}

.title {
  font-weight: 800;
  margin-top: 2rem;
}

.paragraph {
  margin-top: 0.8rem;
  font-size: 0.8rem;
}

.spacer {
  width: 100%;
  height: 2rem;
}

.sub-paragraph {
  margin-top: 0.4rem;
}
</style>
