<template>
  <div class="location-search-container">
    <!-- Search Input -->
    <div class="search-bar">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search"
        @input="onSearchInput"
        class="search-input"
        ref="searchInput"
      />
      <font-awesome-icon
        icon="fa-solid fa-magnifying-glass"
        class="search-icon"
      />
    </div>
    <!-- Suggestions -->
    <div v-if="filteredSuggestions.length > 0" class="suggestions">
      <p>Suggestions</p>
      <ul>
        <li
          v-for="(suggestion, index) in filteredSuggestions"
          :key="index"
          @click="selectLocation(suggestion, index)"
          :class="{ clicked: clickedIndex === index }"
        >
          {{ suggestion }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MyHolidayService from "../../services/MyHolidayService.js";

export default {
  data() {
    return {
      searchQuery: "",
      clickedIndex: null,
      filteredSuggestions: [],
      airports: [], // Store all fetched airports here
      debounceTimeout: null, // For debouncing the search input
    };
  },
  methods: {
    onSearchInput() {
      // Clear previous timeout to debounce
      clearTimeout(this.debounceTimeout);

      // Set a new timeout to trigger search after user stops typing
      this.debounceTimeout = setTimeout(() => {
        this.filterSuggestions();
      }, 300); // Adjust the debounce time if necessary
    },
    async filterSuggestions() {
      if (this.searchQuery) {
        try {
          await this.getFilteredAirports(this.searchQuery);
        } catch (error) {
          console.error("Error fetching airports:", error);
        }
      } else {
        this.filteredSuggestions = [];
      }
    },
    selectLocation(location, index) {
      // Get the code and municipality of the selected airport based on the index
      const selectedAirport = this.airports[index];
      const airportCode = selectedAirport.Code;
      const municipality = selectedAirport.Municipality;

      // Briefly highlight the clicked suggestion
      this.clickedIndex = index;

      // Delay clearing the suggestions to allow the highlight to show
      setTimeout(() => {
        this.searchQuery = location;
        this.filteredSuggestions = []; // Clear suggestions after delay
        this.clickedIndex = null; // Remove the highlight
        // Emit both the airport code and the municipality
        this.$emit("location-selected", { airportCode, municipality });
      }, 50); // Adjust the duration for the highlight as needed
    },
    async getFilteredAirports(search) {
      try {
        const response = await MyHolidayService.getFilteredAirports(search);

        // Store airports
        this.airports = response.data.recordset;

        // Map the airports to the desired format "Municipality - Name - Code"
        this.filteredSuggestions = this.airports.map(
          (airport) =>
            `${airport.Municipality} - ${airport.Name} - ${airport.Code}`
        );
      } catch (error) {
        console.error("Failed to fetch airports:", error);
      }
    },
  },
  mounted() {
    // Ensure the DOM is updated before focusing
    this.$nextTick(() => {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.focus();
      }
    });
  },
};
</script>

<style scoped>
.location-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;
  background-color: var(--color-form-background);
  max-width: 480px;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--vt-c-white);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 1rem;
}

.search-input {
  border: none;
  outline: none;
  font-size: 1rem;
  color: var(--color-text-secondary);
  background-color: var(--color-form-background);
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 0.75rem;
  color: var(--color-text-primary);
}

.search-input::placeholder {
  color: var(--color-form-placeholder);
  font-family: "Sora";
}

.search-icon {
  color: var(--color-text-primary);
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: auto;
  color: var(--color-text-primary);
}

.suggestions {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns the suggestions to the left */
  justify-content: flex-start; /* Ensure that the content starts at the top */
  width: 100%; /* Ensure it takes up the full width of the container */
  box-sizing: border-box; /* Ensure padding is included in the width */
  border-radius: 0.75rem;
  overflow-y: auto;
  max-height: 90vh;
  padding-bottom: 10rem;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.popular-destinations-section::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.suggestions p {
  font-weight: 700;
  font-size: 0.75rem;
  padding: 0 1rem;
  color: var(--color-text-primary);
}

.suggestions ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%; /* Ensure the ul takes up the full width */
  box-sizing: border-box; /* Include padding within width/height */
}

.suggestions li {
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-family: "Sora";
  color: var(--color-text-primary);
  width: 100%; /* Take up the full width of the ul */
  text-align: left;
  font-size: 0.75rem;
  display: block; /* Ensure the li takes up the full width */
  box-sizing: border-box; /* Prevent the padding from increasing the width */
}

.suggestions li:hover {
  background-color: var(--color-button-primary);
  color: var(--vt-c-white); /* Optional for better visibility */
}

.suggestions li.clicked {
  background-color: var(--color-button-primary);
  color: var(--vt-c-white); /* Optional for better visibility */
}
</style>
