<template>
  <div class="profile-container">
    <div class="header">
      <div class="profile-image-container">
        <img
          src="@/assets/profileImage.svg"
          alt="Profile Image"
          class="profile-image"
        />
      </div>
      <div class="username-edit">
        <input
          v-if="isEditing"
          v-model="editableUsername"
          @input="checkUsernameLength"
          @keyup.enter="saveChanges"
          @blur="saveChanges"
          ref="usernameInput"
        />
        <!-- Display the username, and allow editing on click -->
        <span v-else @click="editUsername">{{ editableUsername }}</span>
        <button @click="editUsername" class="btn">
          <font-awesome-icon icon="fa-solid fa-pen-to-square" class="edit" />
        </button>
      </div>
      <p v-if="showError" class="error-message">
        Usernames are limited to 15 characters.
      </p>
    </div>
    <div class="summary">
      <!-- <p class="saved-interests">
        {{ this.numberOfInterests }} Interests Selected
      </p> -->
      <!-- <p class="saved-itineraries">5 Itineraries Saved</p> -->
    </div>
    <div class="profile-list">
      <div class="list-item" @click="editUserDetails">
        <div class="list-header">
          <div class="icon-container">
            <img
              src="@/assets/personalDetails.svg"
              alt="Personal Details"
              class="icon"
            />
          </div>
          <p class="list-heading">Personal Details</p>
        </div>
        <font-awesome-icon icon="fa-solid fa-arrow-right" class="arrow" />
      </div>
      <div class="list-item" @click="selectInterests">
        <div class="list-header">
          <div class="icon-container">
            <img src="@/assets/interests.svg" alt="Interests" class="icon" />
          </div>
          <p class="list-heading">Interests</p>
        </div>
        <font-awesome-icon icon="fa-solid fa-arrow-right" class="arrow" />
      </div>
      <div class="list-item coupon-item" @click="scrollToTop()">
        <div class="list-header">
          <div class="icon-container">
            <img src="@/assets/itineraryDark.svg" alt="Coupons" class="icon" />
          </div>
          <p class="list-heading">My Itineraries</p>
        </div>
        <div class="overlay">
          <p>Coming Soon</p>
        </div>
        <font-awesome-icon icon="fa-solid fa-arrow-right" class="arrow" />
      </div>
      <div class="list-item coupon-item" @click="scrollToTop()">
        <div class="list-header">
          <div class="icon-container">
            <img src="@/assets/coupons.svg" alt="Coupons" class="icon" />
          </div>
          <p class="list-heading">Data Discounts</p>
        </div>
        <div class="overlay">
          <p>Coming Soon</p>
        </div>
        <font-awesome-icon icon="fa-solid fa-arrow-right" class="arrow" />
      </div>
      <div class="list-item" @click="handleTerms">
        <div class="list-header">
          <div class="icon-container">
            <img src="@/assets/settings.svg" alt="Settings" class="icon" />
          </div>
          <p class="list-heading">Terms & Conditions</p>
        </div>
        <font-awesome-icon icon="fa-solid fa-arrow-right" class="arrow" />
      </div>
    </div>
  </div>
  <div class="user-details-container">
    <user-details-editor
      v-if="showUserDetailsEditor"
      :name="editableUsername"
      @saveChanges="onDetailsChanged"
      @update-name="updateUsername"
      @close-modal="closeUserDetailsEditor"
    />
  </div>
  <div class="interest-picker-container">
    <interest-picker
      v-if="showInterestPicker"
      :selectedInterests="userInterests"
      @apply-interests="onInterestsSelected"
      @close-modal="closeInterestPicker"
    />
  </div>
</template>

<script>
import MyHolidayService from "../services/MyHolidayService.js";
import UserDetailsEditor from "../components/navigation/UserDetailsEditor.vue";
import InterestPicker from "../components/navigation/InterestPicker.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    UserDetailsEditor,
    InterestPicker,
  },
  data() {
    return {
      isEditing: false,
      showError: false,
      showUserDetailsEditor: false,
      showInterestPicker: false,
      userInterests: [],
      numberOfInterests: 0,
    };
  },
  computed: {
    ...mapGetters(["username"]), // Get the username and user interests from the Vuex store
    editableUsername: {
      get() {
        return this.username;
      },
      set(value) {
        this.updateUsername(value);
      },
    },
  },
  methods: {
    ...mapActions(["updateUsername"]), // Map actions to update username and save selected interests to Vuex

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scrolling effect
      });
    },
    async saveUsername() {
      const msisdn = this.$store.getters.msisdn;
      this.isEditing = false;
      await MyHolidayService.updateUsername(
        msisdn,
        "myHoliday",
        this.editableUsername
      );
    },
    editUsername() {
      this.isEditing = true;
      this.$nextTick(() => {
        this.$refs.usernameInput.focus();
      });
    },
    saveChanges() {
      this.isEditing = false;
      this.updateUsername(this.editableUsername); // Update the username in Vuex
      this.saveUsername();
    },
    checkUsernameLength() {
      if (this.editableUsername.length > 15) {
        this.editableUsername = this.editableUsername.substr(0, 15);
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
    editUserDetails() {
      this.scrollToTop();
      this.showUserDetailsEditor = true;
      this.toggleBodyScroll(true);
    },
    closeUserDetailsEditor() {
      this.showUserDetailsEditor = false;
      this.toggleBodyScroll(false);
    },
    selectInterests() {
      this.scrollToTop();
      this.showInterestPicker = true;
      this.toggleBodyScroll(true);
    },
    closeInterestPicker() {
      this.showInterestPicker = false;
      this.toggleBodyScroll(false);
    },
    onInterestsSelected() {
      this.closeInterestPicker();
      setTimeout(() => {
        this.getUserInterests();
      }, 500);
      this.getUserInterests();
    },
    showSavedItineraries() {
      this.$router.push("/saved-itineraries");
    },
    handleTerms() {
      this.$router.push("/terms");
    },
    async getUserInterests() {
      // Fetch the phone number (msisdn) from the Vuex store
      const phoneNumber = this.$store.getters.msisdn;

      if (phoneNumber) {
        try {
          // Fetch the user interests using the phone number
          const userInterests = await MyHolidayService.getUserInterests(
            phoneNumber,
            "myHoliday"
          );

          // Map the response to get an array of interests
          const interestsArray = userInterests.data.recordset.map(
            (item) => item.Interest
          );

          this.userInterests = interestsArray;

          this.$store.dispatch("saveUserInterests", interestsArray);

          // Commit the interests to the store
        } catch (error) {
          console.error("Failed to fetch user interests:", error);
        }
      } else {
        console.error("Phone number not found in the store.");
      }
    },
    toggleBodyScroll(lock) {
      if (lock) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    },
  },
  mounted() {
    this.getUserInterests();
  },
};
</script>

<style scoped>
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 1rem 5rem 1rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.profile-image-container {
  width: 7.5rem;
  border-radius: 50rem;
  height: 7.5rem;
  overflow: hidden;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.username-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-primary);
  font-size: 1rem;
  margin-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.15rem solid var(--color-form-background);
  width: 100%;
}

.username-edit input {
  background: transparent;
  border: none;
  font-family: "Sora";
}

.username-edit input:focus {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  outline: none;
  font-size: 1rem;
  color: var(--color-text-primary);
}

.username-edit img {
  cursor: pointer;
}

.btn {
  background: none;
  border: none;
  padding: 0;
  margin-left: 1rem;
  cursor: pointer;
  outline: none;
  color: var(--color-button-primary);
  font-size: 1rem;
}

.error-message {
  color: var(--color-text-primary);
  font-size: 0.75rem;
  margin-top: 1rem;
  text-align: center;
}

.summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  font-size: 0.75rem;
  color: var(--vt-c-blue-secondary);
  gap: 0.3rem;
}

.saved-interests,
.saved-itineraries {
  margin: 0;
}

.profile-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.75rem;
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0.5rem 0;
  background-color: var(--color-form-background);
  border-radius: 0.75rem;
}

.list-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 1rem;
  gap: 0.75rem;
  color: var(--color-text-primary);
  font-size: 0.75rem;
}

.arrow {
  margin: 0 1rem 0 0;
  color: var(--color-text-primary);
  font-size: 1rem;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  background-color: white;
  border-radius: 0.25rem;
}

.icon {
  height: 1.25rem;
}

.user-details-container,
.interest-picker-container {
  z-index: 1000;
  position: absolute;
  width: 100%;
}

.coupon-item {
  position: relative;
  overflow: hidden;
}

.coupon-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(128, 128, 128, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
  color: white;
  font-size: 0.75rem;
  pointer-events: none; /* Prevent clicking on the overlay */
}
</style>
