<template>
  <div class="app-container">
    <router-view></router-view>
    <nav-bar v-if="handleNavbar && !$route.meta.hideNavbar"></nav-bar>
    <search-results-nav
      v-if="!$route.meta.hideSearchResultsNav"
    ></search-results-nav>
  </div>
</template>

<script>
import store from "./store";
import NavBar from "./components/navigation/NavBar.vue";
import SearchResultsNav from "./components/navigation/SearchResultsNav.vue";

export default {
  components: {
    NavBar,
    SearchResultsNav,
  },
  computed: {
    handleNavbar() {
      return store.getters.isAuthenticated;
    },
  },
};
</script>
<style>
@import "./assets/base.css";
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700&display=swap");

html {
  font-family: "Sora", sans-serif;
  font-weight: 400;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: "Sora", sans-serif;
  color: var(--color-text);
  background: var(--color-background);
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  max-width: 480px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.nav-bar {
  z-index: 10000;
}

.router-view {
  flex: 1;
}

.search-results-nav {
  z-index: 10000;
}
</style>
