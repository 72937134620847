<template>
  <div class="saved-itineraries-container">
    <div class="header-navigation" @click="goBack">
      <font-awesome-icon icon="fa-solid fa-angle-left" class="close-icon" />
      <p class="back">Profile</p>
    </div>
    <div class="intro">
      <div class="owl-contianer">
        <img src="@/assets/owlFred.svg" alt="owlFred" class="owlFred" />
      </div>
      <div class="intro-text">
        <p class="hello">Hello John!</p>
        <p class="owl-name">
          I'm <span class="owl-text">OWL</span
          ><span class="fred-text">FRED</span>
        </p>
        <p class="intro-summary">
          Let's pack, I've got your personalized itinerary all set.
        </p>
      </div>
    </div>

    <!-- Itinerary List -->
    <div class="itinerary-list">
      <div
        class="itinerary-item"
        v-for="(itinerary, index) in itineraries"
        :key="index"
      >
        <p class="itinerary-heading">{{ itinerary.name }}</p>
        <div class="item-container">
          <div class="list-header">
            <div class="icon-container">
              <img
                src="@/assets/itineraryDark.svg"
                alt="Personal Details"
                class="icon"
              />
            </div>
            <p class="list-heading">{{ itinerary.city }}</p>
          </div>
          <p class="view-itinerary">View</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itineraries: [
        { name: "Itinerary 1", city: "Paris" },
        { name: "Itinerary 2", city: "London" },
        { name: "Itinerary 3", city: "Tokyo" },
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.push("/profile");
    },
  },
};
</script>

<style scoped>
.saved-itineraries-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  margin-bottom: 5rem;
}

.header-navigation {
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.75rem;
  margin-top: 1rem;
  font-weight: 700;
  color: var(--color-text-primary);
}

.intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
}

.owl-contianer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.owlFred {
  height: 6rem;
}

.intro-text {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0;
}

.hello {
  color: var(--color-text-primary);
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0;
}

.owl-name {
  margin-top: 0.1rem;
  margin-bottom: 0.3rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--color-text-primary);
}

.owl-text {
  color: var(--vt-c-blue-secondary);
}

.fred-text {
  color: var(--vt-c-orange-primary);
}

.intro-summary {
  margin: 0;
  font-size: 0.75rem;
  color: var(--color-text-secondary);
}

.itinerary-list {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.itinerary-heading {
  margin: 0 0 0.3rem 0.75rem;
  font-size: 0.75rem;
  color: var(--color-text-primary);
  font-weight: 700;
}

.item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0.5rem 0;
  background-color: var(--color-form-background);
  border-radius: 0.75rem;
}

.list-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 1rem;
  gap: 0.75rem;
  color: var(--color-text-primary);
  font-size: 0.75rem;
}

.view-itinerary {
  margin: 0 1rem 0 0;
  color: var(--color-text-primary);
  font-size: 0.75rem;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  background-color: white;
  border-radius: 0.25rem;
}

.icon {
  height: 1.25rem;
}
</style>
