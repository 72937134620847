<template>
  <div class="date-picker-container">
    <div class="modal-overlay" @click="closeModal"></div>

    <div class="modal-content">
      <div class="modal-header">
        <p class="heading">Choose Dates</p>
        <div class="close-btn" @click="closeModal">
          <font-awesome-icon icon="fa-solid fa-x" class="close-icon" />
          <p class="close-text">Close</p>
        </div>
      </div>
      <div class="divider"></div>
      <div class="calendar-controls">
        <div class="scroll-container">
          <div @click="prevYear" class="scroll">
            <font-awesome-icon
              icon="fa-solid fa-angle-left"
              class="scroll-icon"
            />
          </div>
          <span class="scroll-heading">{{ selectedYear }}</span>
          <div @click="nextYear" class="scroll">
            <font-awesome-icon
              icon="fa-solid fa-angle-right"
              class="scroll-icon"
            />
          </div>
        </div>
        <div class="scroll-container">
          <div @click="prevMonth" class="scroll">
            <font-awesome-icon
              icon="fa-solid fa-angle-left"
              class="scroll-icon"
            />
          </div>
          <span class="scroll-heading">{{ monthNames[selectedMonth] }}</span>
          <div @click="nextMonth" class="scroll">
            <font-awesome-icon
              icon="fa-solid fa-angle-right"
              class="scroll-icon"
            />
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="modal-body">
        <table class="calendar">
          <thead>
            <tr>
              <th v-for="day in daysOfWeek" :key="day">{{ day }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(week, index) in calendarDays" :key="index">
              <td
                v-for="day in week"
                :key="day.date"
                :class="[
                  isDateSelected(day) ? 'selected' : '',
                  isStartDate(day) ? 'start-date' : '',
                  isEndDate(day) ? 'end-date' : '',
                  isDateInRange(day) ? 'in-range' : '',
                ]"
                @click="selectDate(day)"
              >
                {{ day.date }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="modal-footer">
        <button @click="applyDate" class="btn-done">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isModalOpen: true,
      selectedYear: new Date().getFullYear(),
      selectedMonth: new Date().getMonth(),
      startDate: null,
      endDate: null,
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {
    calendarDays() {
      const firstDay = new Date(
        this.selectedYear,
        this.selectedMonth,
        1
      ).getDay();
      const lastDate = new Date(
        this.selectedYear,
        this.selectedMonth + 1,
        0
      ).getDate();
      const daysArray = [];
      let week = [];

      for (let i = 0; i < firstDay; i++) {
        week.push({ date: "", selected: false });
      }

      for (let day = 1; day <= lastDate; day++) {
        week.push({
          date: day,
          selected: false,
          fullDate: new Date(this.selectedYear, this.selectedMonth, day),
        });
        if (week.length === 7) {
          daysArray.push(week);
          week = [];
        }
      }

      if (week.length) {
        while (week.length < 7) {
          week.push({ date: "", selected: false });
        }
        daysArray.push(week);
      }

      return daysArray;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal"); // Emit a separate event to close the modal
      this.isModalOpen = false;
    },
    prevYear() {
      this.selectedYear--;
    },
    nextYear() {
      this.selectedYear++;
    },
    prevMonth() {
      if (this.selectedMonth === 0) {
        this.selectedMonth = 11;
        this.selectedYear--;
      } else {
        this.selectedMonth--;
      }
    },
    nextMonth() {
      if (this.selectedMonth === 11) {
        this.selectedMonth = 0;
        this.selectedYear++;
      } else {
        this.selectedMonth++;
      }
    },
    selectDate(day) {
      if (!day.date) return;

      const fullDate = new Date(
        this.selectedYear,
        this.selectedMonth,
        day.date
      );

      if (!this.startDate) {
        this.startDate = fullDate;
      } else if (!this.endDate) {
        if (fullDate > this.startDate) {
          this.endDate = fullDate;
        } else {
          this.startDate = fullDate;
        }
      } else {
        this.startDate = fullDate;
        this.endDate = null;
      }
    },
    isDateSelected(day) {
      if (!day.date) return false;
      const fullDate = new Date(
        this.selectedYear,
        this.selectedMonth,
        day.date
      );
      return (
        fullDate.getTime() === this.startDate?.getTime() ||
        fullDate.getTime() === this.endDate?.getTime()
      );
    },
    isDateInRange(day) {
      if (!day.date || !this.startDate || !this.endDate) return false;
      const fullDate = new Date(
        this.selectedYear,
        this.selectedMonth,
        day.date
      );
      return fullDate > this.startDate && fullDate < this.endDate;
    },
    isStartDate(day) {
      if (!day.date) return false;
      const fullDate = new Date(
        this.selectedYear,
        this.selectedMonth,
        day.date
      );
      return fullDate.getTime() === this.startDate?.getTime();
    },
    isEndDate(day) {
      if (!day.date) return false;
      const fullDate = new Date(
        this.selectedYear,
        this.selectedMonth,
        day.date
      );
      return fullDate.getTime() === this.endDate?.getTime();
    },
    applyDate() {
      if (this.startDate && this.endDate) {
        this.$store.dispatch("saveDates", {
          startDate: this.startDate,
          endDate: this.endDate,
        });
        this.$emit("dates-selected", {
          startDate: this.startDate,
          endDate: this.endDate,
        });
      }
      this.closeModal(); // Close the modal after applying the date
    },
  },
};
</script>

<style scoped>
.date-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;
  background-color: rgba(3, 34, 58, 0.8);
  max-width: 480px;
}

.modal-overlay {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: relative;
}

.modal-content {
  position: absolute;
  bottom: 0;
  background-color: var(--vt-c-white);
  border-radius: 2rem 2rem 0 0;
  z-index: 1001;
  width: 100%;
  max-width: 480px;
  padding-bottom: 10rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 1rem 1rem;
}

.heading {
  color: var(--color-text-primary);
  font-weight: 700;
  font-size: 0.75rem;
}

.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 1rem;
  background-color: var(--color-form-background);
  border-radius: 0.75rem;
  color: var(--color-text-primary);
  font-size: 0.6rem;
}

.modal-body {
  margin: 1rem;
}

.calendar-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}

.scroll-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.scroll {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-text-primary);
  color: var(--vt-c-white);
  font-size: 1rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.4rem;
}

.scroll-heading {
  color: var(--color-text-primary);
  font-size: 0.75rem;
}

.calendar {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.75rem;
}

.calendar th,
.calendar td {
  text-align: center;
  padding: 0.75rem 0;
  color: var(--color-text-primary);
}

.calendar td {
  cursor: pointer;
}

.calendar td.selected {
  background-color: var(--vt-c-blue-secondary);
  color: var(--vt-c-white);
}

.calendar td.start-date {
  background-color: var(--vt-c-blue-secondary);
  color: var(--vt-c-white);
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}

.calendar td.end-date {
  background-color: var(--vt-c-blue-secondary);
  color: var(--vt-c-white);
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.calendar td.in-range {
  background-color: var(--vt-c-blue-secondary);
  color: var(--vt-c-white);
}

.modal-footer {
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 1rem;
}

.btn-done {
  background-color: var(--vt-c-blue-secondary);
  color: var(--vt-c-white);
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 1.3rem 2rem;
  font-size: 0.75rem;
  font: "Sora";
  font-weight: 600;
  border-radius: 0.5rem;
}

.divider {
  height: 0.1rem;
  background-color: var(--color-form-divider);
  margin: 0 1rem;
}
</style>
