<template>
  <div class="itinerary-overview-container">
    <div class="header">
      <p class="day">Day {{ day.day }}</p>
    </div>
    <div class="day-section">
      <div class="day-section-header">
        <img src="@/assets/morning.svg" alt="Morning" class="icon" />
        <p class="time-of-day">Morning</p>
      </div>
      <div class="activity-description">
        <p class="description">{{ day.morning }}</p>
      </div>
    </div>
    <div class="day-section">
      <div class="day-section-header">
        <img src="@/assets/afternoon.svg" alt="Afternoon" class="icon" />
        <p class="time-of-day">Afternoon</p>
      </div>
      <div class="activity-description">
        <p class="description">{{ day.afternoon }}</p>
      </div>
    </div>
    <div class="day-section">
      <div class="day-section-header">
        <img src="@/assets/evening.svg" alt="Evening" class="icon" />
        <p class="time-of-day">Evening</p>
      </div>
      <div class="activity-description">
        <p class="description">{{ day.evening }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    day: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.itinerary-overview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 480px;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-form-background);
  padding: 0 0.75rem;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width */
  color: var(--color-text-primary);
  font-size: 0.75rem;
  font-weight: 800;
  border-radius: 0.5rem;
}

.day-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  width: 100%;
}

.day-section-header {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.75rem;
  color: var(--vt-c-blue-secondary);
  font-size: 0.75rem;
  font-weight: 800;
}

.icon {
  height: 2rem;
  width: 2rem;
}

.activity-description {
  padding: 0 0.75rem;
  color: var(--color-text-secondary);
  font-size: 0.75rem;
}

.description {
  margin: 0;
}
</style>
