<template>
  <div class="interest-picker-container">
    <div class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <p class="heading">Add Interests</p>
          <div class="close-btn" @click="closeModal">
            <font-awesome-icon icon="fa-solid fa-x" class="close-icon" />
            <p class="close-text">Close</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="selected-interests">
          <div v-if="selectedInterestsList.length">
            <span
              v-for="interest in selectedInterestsList"
              :key="interest.label"
              class="selected-interest-item"
            >
              {{ interest.label }}
            </span>
          </div>
          <div v-else class="interests-placeholder">
            No interests selected yet
          </div>
        </div>
        <div class="divider"></div>
        <div class="modal-body">
          <div
            class="interest-container"
            v-for="(interest, index) in sortedInterests"
            :key="index"
          >
            <div class="interest-header">
              <div class="icon-container">
                <img :src="interest.icon" :alt="interest.label" class="icon" />
              </div>
              <p class="label">{{ interest.label }}</p>
            </div>
            <div class="checked-container">
              <label class="checkbox-container">
                <input type="checkbox" v-model="interest.selected" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="applyChoices" class="btn-done">Done</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyHolidayService from "../../services/MyHolidayService.js";

export default {
  props: {
    selectedInterests: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      interests: [
        { label: "Art", icon: require("@/assets/art.svg"), selected: false },
        {
          label: "History",
          icon: require("@/assets/history.svg"),
          selected: false,
        },
        {
          label: "Photography",
          icon: require("@/assets/photography.svg"),
          selected: false,
        },
        {
          label: "Night Life",
          icon: require("@/assets/nightLife.svg"),
          selected: false,
        },
        {
          label: "Museums",
          icon: require("@/assets/museums.svg"),
          selected: false,
        },
        {
          label: "Outdoors",
          icon: require("@/assets/outdoors.svg"),
          selected: false,
        },
        {
          label: "Food & Drink",
          icon: require("@/assets/foodAndDrink.svg"),
          selected: false,
        },
      ],
    };
  },
  computed: {
    selectedInterestsList() {
      return this.interests
        .filter((interest) => interest.selected)
        .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically by label
    },
    sortedInterests() {
      // Sort the interests alphabetically by their label
      return this.interests
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));
    },
  },
  watch: {
    selectedInterests: {
      immediate: true,
      handler(newVal) {
        // Sync the selected interests passed from HomeView
        this.interests.forEach((interest) => {
          interest.selected = newVal.some(
            (selectedInterest) => selectedInterest === interest.label
          );
        });
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    applyChoices() {
      const selectedInterests = this.interests.filter(
        (interest) => interest.selected
      );

      // Emit selected interests
      this.$emit("apply-interests", selectedInterests);

      // Check if the current route is /profile
      if (this.$route.path === "/profile") {
        const userInterests = this.interests
          .filter((interest) => interest.selected)
          .map((interest) => interest.label);

        this.upsertUserInterests(userInterests);
        this.$emit("apply-interests", selectedInterests);
      }

      this.closeModal();
    },
    async upsertUserInterests(interests) {
      // Fetch the phone number (msisdn) from the Vuex store
      const phoneNumber = this.$store.getters.msisdn;

      try {
        await MyHolidayService.upsertUserInterests(
          phoneNumber,
          "myHoliday",
          interests
        );
      } catch (error) {
        console.error("Failed to upsert user interests:", error);
      }
    },
  },
};
</script>

<style scoped>
.interest-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;
  background-color: rgba(3, 34, 58, 0.8);
  max-width: 480px;
}

.modal-overlay {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: relative;
}

.modal-content {
  position: absolute;
  bottom: 0;
  background-color: var(--vt-c-white);
  border-radius: 2rem 2rem 0 0;
  z-index: 1001;
  width: 100%;
  max-width: 480px;
  padding-bottom: 10rem;
  max-height: 80vh;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 1rem 1rem;
}

.heading {
  color: var(--color-text-primary);
  font-weight: 700;
  font-size: 0.75rem;
}

.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 1rem;
  background-color: var(--color-form-background);
  border-radius: 0.75rem;
  color: var(--color-text-primary);
  font-size: 0.6rem;
}

.modal-body {
  margin: 1rem;
  max-height: 30vh; /* Adjust the height as needed */
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.modal-body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.interest-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  padding: 0.25rem 0;
}

.interest-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-size: 0.75rem;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-form-background);
  height: 2rem;
  width: 2rem;
  border-radius: 0.25rem;
}

.icon {
  height: 1.25rem;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

/* Create the custom checkbox look */
.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: self-start;
}

.checkbox-container .checkmark {
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid var(--color-form-label); /* Default unchecked border color */
  background-color: var(--vt-c-white); /* Default unchecked background */
  border-radius: 4px; /* Rounded corners */
  position: relative;
  display: inline-block;
}

/* Change the color when the checkbox is checked */
.checkbox-container input[type="checkbox"]:checked + .checkmark {
  background-color: var(--color-button-primary); /* Checked background color */
  border-color: var(--color-button-primary); /* Checked border color */
}

/* Add a checkmark icon when the box is checked */
.checkbox-container input[type="checkbox"]:checked + .checkmark::after {
  content: "";
  position: absolute;
  left: 0.23rem;
  top: 0.07rem;
  width: 0.2rem;
  height: 0.4rem;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Change the hover effect */
.checkbox-container:hover .checkmark {
  border-color: #888; /* Slightly darker on hover */
}

.selected-interests {
  margin: 0 1rem 1rem 1rem;
  font-size: 0.75rem;
  color: var(--color-text-primary);
}

.interests-placeholder {
  margin-top: 1rem;
}

.selected-interest-item {
  display: inline-block;
  background-color: var(--color-form-background);
  padding: 0.3rem 0.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
}

.modal-footer {
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 1rem;
}

.btn-done {
  background-color: var(--vt-c-blue-secondary);
  color: var(--vt-c-white);
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 1.3rem 2rem;
  font-size: 0.75rem;
  font: "Sora";
  font-weight: 600;
  border-radius: 0.5rem;
}

.divider {
  height: 0.1rem;
  background-color: var(--color-form-divider);
  margin: 0 1rem;
}
</style>
