<template>
  <div class="city-overview-container">
    <p class="header">{{ city.name }}</p>
    <div class="city-image-container">
      <img :src="cityImage" alt="City Image" class="city-image" />
    </div>
    <p class="attractions-header">Tourist Attractions</p>
    <div class="tourist-attractions">
      <div
        v-for="attraction in sortedAttractions"
        :key="attraction"
        class="attraction"
      >
        <p class="attraction-text">{{ attraction }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    city: {
      type: Object,
      required: true,
    },
    cityImage: {
      type: String,
      required: true,
    },
  },
  computed: {
    sortedAttractions() {
      return [...this.city.attractions].sort();
    },
  },
};
</script>

<style scoped>
.city-overview-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  max-width: 100%; /* Remove the fixed max-width */
  width: 100%; /* Ensure full width of the parent */
  box-sizing: border-box;
  margin: 0; /* Remove any margin that might restrict full width */
  padding: 0; /* Ensure no padding affects the full width */
}

.header {
  font-size: 0.9rem;
  color: var(--color-text-primary);
  margin: 1rem 0 0.5rem 0.75rem;
  font-weight: 800;
}

.city-image-container {
  width: 100%; /* Full width */
  border-radius: 0.75rem;
  height: 9rem; /* Adjust height */
  overflow: hidden;
}

.city-image {
  width: 100%; /* Full width */
  height: 100%; /* Takes up full height of the container */
  object-fit: cover; /* Maintain aspect ratio while filling container */
}

.attractions-header {
  font-size: 0.75rem;
  color: var(--color-text-primary);
  margin: 0.75rem 0 0 0;
}

.tourist-attractions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  margin-top: 0.5rem;
}

.attraction {
  font-size: 0.75rem;
  background-color: var(--color-form-background);
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
}

.attraction-text {
  margin: 0;
  color: var(--color-text-secondary);
}

.btn-explore {
  background-color: var(--vt-c-blue-secondary);
  color: var(--vt-c-white);
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 1rem 2rem;
  font-size: 0.75rem;
  font: "Sora";
  font-weight: 600;
  border-radius: 0.5rem;
  margin-top: 1rem;
}
</style>
