<template>
  <div class="room-picker-container">
    <div class="modal-overlay" @click="closeModal"></div>
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading">Number of Rooms & Guests</p>
        <div class="close-btn" @click="closeModal">
          <font-awesome-icon icon="fa-solid fa-x" class="close-icon" />
          <p class="close-text">Close</p>
        </div>
      </div>
      <div class="divider"></div>
      <div class="modal-body">
        <!-- Rooms Selector -->
        <div class="selecter-container">
          <div class="selector-header">
            <div class="icon-container">
              <img src="@/assets/door.svg" alt="Door" class="icon" />
            </div>
            <p class="label">Rooms</p>
          </div>
          <div class="scroll-container">
            <div class="scroll" @click="decrementRooms">
              <font-awesome-icon
                icon="fa-solid fa-angle-left"
                class="scroll-icon"
              />
            </div>
            <span class="scroll-heading">{{ selectedRooms }}</span>
            <div class="scroll" @click="incrementRooms">
              <font-awesome-icon
                icon="fa-solid fa-angle-right"
                class="scroll-icon"
              />
            </div>
          </div>
        </div>
        <!-- Adults Selector -->
        <div class="selecter-container">
          <div class="selector-header">
            <div class="icon-container">
              <img src="@/assets/adult.svg" alt="Adult" class="icon" />
            </div>
            <p class="label">Adults</p>
          </div>
          <div class="scroll-container">
            <div class="scroll" @click="decrementAdults">
              <font-awesome-icon
                icon="fa-solid fa-angle-left"
                class="scroll-icon"
              />
            </div>
            <span class="scroll-heading">{{ selectedAdults }}</span>
            <div class="scroll" @click="incrementAdults">
              <font-awesome-icon
                icon="fa-solid fa-angle-right"
                class="scroll-icon"
              />
            </div>
          </div>
        </div>
        <!-- Children Selector -->
        <div class="selecter-container">
          <div class="selector-header">
            <div class="icon-container">
              <img src="@/assets/child.svg" alt="Children" class="icon" />
            </div>
            <p class="label">Children</p>
          </div>
          <div class="scroll-container">
            <div class="scroll" @click="decrementChildren">
              <font-awesome-icon
                icon="fa-solid fa-angle-left"
                class="scroll-icon"
              />
            </div>
            <span class="scroll-heading">{{ selectedChildren }}</span>
            <div class="scroll" @click="incrementChildren">
              <font-awesome-icon
                icon="fa-solid fa-angle-right"
                class="scroll-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button @click="applyChoices" class="btn-done">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isModalOpen: true,
      selectedRooms: 0,
      selectedAdults: 0,
      selectedChildren: 0,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal"); // Emit a separate event to close the modal
      this.isModalOpen = false;
    },
    incrementRooms() {
      this.selectedRooms++;
    },
    decrementRooms() {
      if (this.selectedRooms > 0) {
        this.selectedRooms--;
      }
    },
    incrementAdults() {
      this.selectedAdults++;
    },
    decrementAdults() {
      if (this.selectedAdults > 0) {
        this.selectedAdults--;
      }
    },
    incrementChildren() {
      this.selectedChildren++;
    },
    decrementChildren() {
      if (this.selectedChildren > 0) {
        this.selectedChildren--;
      }
    },
    applyChoices() {
      this.$emit("apply-choices", {
        rooms: this.selectedRooms,
        adults: this.selectedAdults,
        children: this.selectedChildren,
      });
      // Commit the selected values to the Vuex store
      this.$store.commit("setGuests", {
        rooms: this.selectedRooms,
        adults: this.selectedAdults,
        children: this.selectedChildren,
      });

      // Optionally, close the modal when done
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.room-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;
  background-color: rgba(3, 34, 58, 0.8);
  max-width: 480px;
}

.modal-overlay {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: relative;
}

.modal-content {
  position: absolute;
  bottom: 0;
  background-color: var(--vt-c-white);
  border-radius: 2rem 2rem 0 0;
  z-index: 1001;
  width: 100%;
  max-width: 480px;
  padding-bottom: 10rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 1rem 1rem;
}

.heading {
  color: var(--color-text-primary);
  font-weight: 700;
  font-size: 0.75rem;
}

.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 1rem;
  background-color: var(--color-form-background);
  border-radius: 0.75rem;
  color: var(--color-text-primary);
  font-size: 0.6rem;
}

.modal-body {
  margin: 1rem;
}

.selecter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-form-background);
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}

.selector-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-size: 0.75rem;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--vt-c-white);
  height: 2rem;
  width: 2rem;
  border-radius: 0.25rem;
}

.icon {
  height: 1.25rem;
}

.scroll-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.scroll {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-text-primary);
  color: var(--vt-c-white);
  font-size: 1rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.4rem;
}

.scroll-heading {
  color: var(--color-text-primary);
  font-size: 0.75rem;
}

.modal-footer {
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 1rem;
}

.btn-done {
  background-color: var(--vt-c-blue-secondary);
  color: var(--vt-c-white);
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 1.3rem 2rem;
  font-size: 0.75rem;
  font: "Sora";
  font-weight: 600;
  border-radius: 0.5rem;
}

.divider {
  height: 0.1rem;
  background-color: var(--color-form-divider);
  margin: 0 1rem;
}
</style>
