<template>
  <div class="home-container">
    <div class="greeting">
      <p class="name">Hello {{ username }}!</p>
      <p class="welcome">Welcome to your personalized AI itinerary helper.</p>
    </div>
    <div class="base-card">
      <div class="info-pickers">
        <div
          class="picker"
          @click="
            scrollToTop();
            showDatePicker = true;
            toggleBodyScroll(true);
          "
        >
          <div class="heading">
            <img src="@/assets/calendar.svg" alt="Calendar" class="icon" />
            <p class="label">Choose Dates</p>
          </div>
          <div class="info">
            <p class="chosen-info">{{ selectedDates || "Select Dates" }}</p>
          </div>
        </div>
        <div
          class="picker"
          @click="
            scrollToTop();
            showRoomPicker = true;
            toggleBodyScroll(true);
          "
        >
          <div class="heading">
            <img src="@/assets/door.svg" alt="Door" class="icon" />
            <p class="label">Number of Rooms</p>
          </div>
          <div class="info">
            <p class="chosen-info">
              {{ roomDisplay || "Select Rooms" }}
            </p>
          </div>
        </div>
      </div>
      <div class="airport-details">
        <!-- To (Destination) Search Box -->
        <div
          class="search-destinations"
          @click="
            scrollToTop();
            showSearchLocation = 'to';
            toggleBodyScroll(true);
          "
        >
          <img
            src="@/assets/searchLogo.svg"
            alt="Search Logo"
            class="search-logo"
          />
          <div class="search-box">
            <div
              class="selected-destination"
              :class="{
                'placeholder-text': !selectedToLocation,
                'selected-text': selectedToLocation,
              }"
            >
              {{ selectedToLocation || "Destination" }}
            </div>
            <font-awesome-icon
              icon="fa-solid fa-magnifying-glass"
              class="search-icon"
            />
          </div>
        </div>
        <!-- From (Departure) Search Box -->
        <div
          class="search-destinations"
          v-if="selectedToLocation"
          @click="
            scrollToTop();
            showSearchLocation = 'from';
            toggleBodyScroll(true);
          "
        >
          <img
            src="@/assets/travelLogo.svg"
            alt="Search Logo"
            class="search-logo"
          />
          <div class="search-box">
            <div
              class="selected-destination"
              :class="{
                'placeholder-text': !selectedFromLocation,
                'selected-text': selectedFromLocation,
              }"
            >
              {{ selectedFromLocation || "Departure" }}
            </div>
            <font-awesome-icon
              icon="fa-solid fa-magnifying-glass"
              class="search-icon"
            />
          </div>
        </div>
      </div>

      <!-- Location Search Component -->
      <location-search
        v-if="showSearchLocation"
        @location-selected="onLocationSelected"
        class="location-search-component"
      />
      <div v-if="selectedFromLocation" class="search-footer">
        <label class="checkbox-container">
          <input
            type="checkbox"
            v-model="includeCustomItinerary"
            @change="onCheckboxChange"
          />
          <span class="checkmark"></span>
          <span class="checkbox-label">Include custom itinerary</span>
        </label>
        <p v-if="showErrorMessage" class="error-message">
          Please complete all fields.
        </p>
        <p v-if="showItineraryErrorMessage" class="itinerary-error-message">
          Please select at least one interest.
        </p>
        <button
          v-if="!showErrorMessage && !showItineraryErrorMessage"
          @click="applyChoices"
          class="btn-search"
        >
          Search
        </button>
      </div>
    </div>
    <p class="section-title">Your Travelling Interests</p>
    <div class="interests-section">
      <div v-if="allInterests.length === 0" class="no-interests">
        <p>No interests selected</p>
      </div>
      <div v-else class="interests-list">
        <div
          v-for="(interest, index) in sortedDisplayedInterests"
          :key="index"
          class="interest-item"
        >
          {{ interest }}
          <button @click="removeInterest(index)" class="remove-interest">
            <font-awesome-icon icon="fa-solid fa-x" />
          </button>
        </div>
        <div
          class="more-interests"
          v-if="sortedHiddenInterests.length > 0"
          @click="showAll = !showAll"
        >
          <span v-if="!showAll">+{{ sortedHiddenInterests.length }} More</span>
          <span v-else>Show Less</span>
        </div>
      </div>
      <div class="buttons-container">
        <button class="save-btn" @click="saveInterests">Save to Profile</button>
        <button class="add-new-btn" @click="addNewInterest">Add New</button>
      </div>
    </div>
    <p class="section-title">Popular Destinations</p>
    <div class="popular-destinations-section">
      <div class="destinations-list">
        <div
          v-for="destination in popularDestinations"
          :key="destination.name"
          class="destination-tile"
          @click="navigateTo(destination.name)"
        >
          <img
            :src="destination.image"
            :alt="destination.name"
            class="destination-image"
          />
          <p class="destination-name">{{ destination.name }}</p>
        </div>
      </div>
    </div>
    <p class="section-title">Popular Hotels</p>
    <div class="popular-hotels-section">
      <div class="hotels-list">
        <div
          v-for="hotel in popularHotels"
          :key="hotel.name"
          class="hotel-tile"
        >
          <img :src="hotel.image" :alt="hotel.name" class="hotel-image" />
          <p class="hotel-location">{{ hotel.location }}</p>
          <div class="hotel-info">
            <div class="hotel-rating" v-if="hotel.rating && hotel.rating >= 0">
              <!-- Loop through the number of full stars -->
              <font-awesome-icon
                v-for="n in Math.floor(hotel.rating)"
                :key="n"
                icon="fa-solid fa-star"
                class="star-icon"
              />

              <!-- Conditionally show a half-star if the rating has a decimal -->
              <font-awesome-icon
                v-if="hotel.rating % 1 !== 0"
                icon="fa-solid fa-star-half-stroke"
                class="star-icon"
              />

              <!-- Fill the rest with empty stars to make a total of 5 -->
              <font-awesome-icon
                v-for="n in 5 - Math.ceil(hotel.rating)"
                :key="n"
                icon="fa-regular fa-star"
                class="star-icon"
              />
            </div>
            <p v-else>No Rating Available</p>
            <div class="hotel-details">
              <p class="hotel-name">{{ hotel.name }}</p>
              <p class="hotel-price">{{ hotel.price }}/night</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="location-search-container">
    <location-search
      v-if="showSearchLocation"
      @location-selected="onLocationSelected"
      class="location-search-component"
    />
  </div>
  <div class="date-picker-container">
    <date-picker
      v-if="showDatePicker"
      @dates-selected="onDatesSelected"
      @close-modal="
        showDatePicker = false;
        toggleBodyScroll(false);
      "
      class="date-picker-component"
    />
  </div>
  <div class="room-picker-container">
    <room-picker
      v-if="showRoomPicker"
      @apply-choices="onRoomsSelected"
      @close-modal="
        showRoomPicker = false;
        toggleBodyScroll(false);
      "
    />
  </div>
  <div class="interest-picker-container">
    <interest-picker
      v-if="showInterestPicker"
      :selectedInterests="allInterests"
      @apply-interests="onInterestsSelected"
      @close-modal="
        showInterestPicker = false;
        toggleBodyScroll(false);
      "
    />
  </div>
</template>

<script>
import MyHolidayService from "../services/MyHolidayService.js";

import LocationSearch from "../components/navigation/LocationSearch.vue";
import DatePicker from "../components/navigation/DatePicker.vue";
import RoomPicker from "../components/navigation/RoomPicker.vue";
import InterestPicker from "../components/navigation/InterestPicker.vue";

import france from "../assets/france.jpg";
import italy from "../assets/italy.jpg";
import usa from "../assets/usa.jpg";
import southAfrica from "../assets/southAfrica.jpg";
import japan from "../assets/japan.jpg";
import hotel2 from "../assets/hotel2.jpg";
import hotel3 from "../assets/hotel3.jpg";
import hotel4 from "../assets/hotel4.jpg";

export default {
  components: {
    LocationSearch,
    DatePicker,
    RoomPicker,
    InterestPicker,
  },
  data() {
    return {
      username: "",
      maxVisible: 6,
      showAll: false, // This flag controls whether to show all interests or not
      showSearchLocation: false, // Control the visibility of the SearchLocation component
      showDatePicker: false, // Control the visibility of the SearchLocation component
      showRoomPicker: false,
      showInterestPicker: false,
      selectedFromLocation: "", // State for the "from" location
      selectedToLocation: "", // State for the "to" location
      selectedDates: "",
      selectedRooms: 0,
      selectedAdults: 0,
      selectedChildren: 0,
      selectedMunicipality: "",
      includeCustomItinerary: true,
      sendCustomItinerary: true,
      allInterests: [],
      popularDestinations: [
        {
          image: france,
          name: "France",
          link: "/explore/france",
        },
        {
          image: italy,
          name: "Italy",
          link: "/explore/italy",
        },
        {
          image: usa,
          name: "USA",
          link: "/explore/usa",
        },
        {
          image: southAfrica,
          name: "South Africa",
          link: "/explore/southAfrica",
        },
        {
          image: japan,
          name: "Japan",
          link: "/explore/japan",
        },
      ],
      popularHotels: [
        // {
        //   image: hotel1,
        //   name: "Golden Sands Resort",
        //   location: "Madison, WI",
        //   rating: 3.5, // Use this to dynamically display stars
        //   price: "$1500",
        //   link: "/explore/hotel1",
        // },
        {
          image: hotel2,
          name: "Hotel Luxe",
          location: "Rome, Italy",
          rating: 4.5,
          price: "R11000",
          link: "/explore/italy",
        },
        {
          image: hotel3,
          name: "Mountain Inn",
          location: "Athens, Greece",
          rating: 4,
          price: "R8500",
          link: "/explore/greece",
        },
        {
          image: hotel4,
          name: "Beachside Retreat",
          location: "Cape Town, South Africa",
          rating: 5,
          price: "R2200",
          link: "/explore/southAfrica",
        },
      ],
    };
  },
  computed: {
    showErrorMessage() {
      return (
        this.selectedDates === "" || // Check if dates are not selected
        this.selectedAdults < 1 || // Check if at least one adult is selected
        this.selectedRooms < 1 || // Check if at least one room is selected
        this.selectedFromLocation === "" || // Check if "from" location is not selected
        this.selectedToLocation === "" // Check if "to" location is not selected
      );
    },
    showItineraryErrorMessage() {
      if (this.allInterests.length < 1 && this.includeCustomItinerary) {
        return true;
      }
      return false;
    },
    sortedInterests() {
      return (this.allInterests || [])
        .slice()
        .sort((a, b) => a.localeCompare(b));
    },
    // Return sorted interests to be displayed, up to maxVisible
    sortedDisplayedInterests() {
      return this.showAll
        ? this.sortedInterests
        : this.sortedInterests.slice(0, this.maxVisible);
    },
    // Return sorted hidden interests (those beyond maxVisible)
    sortedHiddenInterests() {
      return this.sortedInterests.slice(this.maxVisible);
    },
    roomDisplay() {
      const rooms = this.$store.state.selectedRooms;
      const adults = this.$store.state.selectedAdults;
      const children = this.$store.state.selectedChildren;

      if (rooms === 0 && adults === 0 && children === 0) {
        return null; // Return null if no selections have been made
      }

      const roomText = rooms === 1 ? "Room" : "Rooms";
      const adultText = adults === 1 ? "Adult" : "Adults";
      const childrenText = children === 1 ? "Child" : "Children";

      return `${rooms} ${roomText}, ${adults} ${adultText}, ${children} ${childrenText}`;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scrolling effect
      });
    },
    onCheckboxChange() {
      this.sendCustomItinerary = !this.sendCustomItinerary;
      this.$store.dispatch("saveSendCustomItinerary", this.sendCustomItinerary);
    },
    removeInterest(index) {
      this.allInterests.splice(index, 1);
    },
    saveInterests() {
      this.upsertUserInterests(this.allInterests);
    },
    addNewInterest() {
      this.scrollToTop();
      this.showInterestPicker = true;
      this.toggleBodyScroll(true); // Show InterestPicker modal
    },
    navigateTo(countryName) {
      this.$router.push({ path: "/explore", query: { country: countryName } });
    },
    toggleBodyScroll(lock) {
      if (lock) {
        document.body.style.overflow = "hidden"; // Lock the scroll
      } else {
        document.body.style.overflow = ""; // Unlock the scroll
      }
    },
    onLocationSelected(locationData) {
      // locationData will now have both airportCode and municipality
      const { airportCode, municipality } = locationData;

      // Check which search box was opened and update the respective state
      if (this.showSearchLocation === "from") {
        this.selectedFromLocation = airportCode;
      } else if (this.showSearchLocation === "to") {
        this.selectedToLocation = airportCode;
        this.selectedMunicipality = municipality; // Store the selected municipality
        this.$store.dispatch("saveMunicipality", municipality);
      }

      // Close the search component and unlock scrolling
      this.showSearchLocation = false;
      this.toggleBodyScroll(false);
    },
    onDatesSelected(dates) {
      // Format the start and end dates into '23 Aug '24 - 28 Aug '24' format
      const options = { day: "numeric", month: "short", year: "2-digit" };
      const formattedStartDate = dates.startDate.toLocaleDateString(
        "en-GB",
        options
      );
      const formattedEndDate = dates.endDate.toLocaleDateString(
        "en-GB",
        options
      );

      this.selectedDates = `${formattedStartDate} - ${formattedEndDate}`;
      this.showDatePicker = false;
      this.toggleBodyScroll(false); // Unlock scroll when closing DatePicker
    },
    onRoomsSelected(choices) {
      // Commit the updated values to the Vuex store
      // this.$store.commit("setGuests", {
      //   rooms,
      //   adults,
      //   children,
      // });

      this.selectedAdults = choices.adults;
      this.selectedRooms = choices.rooms;
      this.selectedChildren = choices.children;

      // Close the room picker modal and unlock body scroll
      this.showRoomPicker = false;
      this.toggleBodyScroll(false);
    },
    onInterestsSelected(selectedInterests) {
      // Replace the allInterests array with the selected interests
      this.allInterests = selectedInterests.map((interest) => interest.label);
      this.showInterestPicker = false; // Hide the modal after selection
      this.toggleBodyScroll(false);
      this.$store.dispatch("saveTavelInterests", this.allInterests);
    },
    async getFlightOffers() {
      try {
        // Fetch the necessary details from Vuex store
        const fromLocation =
          this.selectedFromLocation || this.$store.state.selectedFromLocation;
        const toLocation =
          this.selectedToLocation || this.$store.state.selectedToLocation;
        const { startDate, endDate } = this.$store.getters.selectedDates;
        const adults = this.selectedAdults || this.$store.state.selectedAdults;
        const children =
          this.selectedChildren || this.$store.state.selectedChildren;

        // Format the start and end dates into 'YYYY-MM-DD' format
        const startDateObj = new Date(startDate); // Create a new Date object from startDate
        startDateObj.setDate(startDateObj.getDate() + 1); // Add one day

        const formattedStartDate = startDateObj.toISOString().split("T")[0]; // Format to YYYY-MM-DD

        const endDateObj = new Date(endDate); // Create a new Date object from startDate
        endDateObj.setDate(endDateObj.getDate() + 1); // Add one day

        const formattedEndDate = endDateObj.toISOString().split("T")[0]; // Format to YYYY-MM-DD

        // Fetch departure flights
        const departureFlights = await MyHolidayService.getFlightOffers({
          originLocationCode: fromLocation,
          destinationLocationCode: toLocation,
          departureDate: formattedStartDate,
          returnDate: formattedEndDate,
          adults,
          children,
          nonStop: true,
          currencyCode: "ZAR",
          max: 20,
        });

        // Fetch return flights
        const returnFlights = await MyHolidayService.getFlightOffers({
          originLocationCode: toLocation,
          destinationLocationCode: fromLocation,
          departureDate: formattedEndDate,
          adults,
          children,
          nonStop: true,
          currencyCode: "ZAR",
          max: 20,
        });

        // Save flights to Vuex store
        this.$store.dispatch("saveDepartureFlights", departureFlights.data);
        this.$store.dispatch("saveReturnFlights", returnFlights.data);
      } catch (error) {
        console.error("Failed to fetch flights:", error);
      }
    },
    async getHotelOffers() {
      try {
        // Fetch the necessary details from Vuex store or data
        const toLocation =
          this.selectedToLocation || this.$store.state.selectedToLocation;
        const { startDate, endDate } = this.$store.getters.selectedDates;
        const adults = this.selectedAdults || this.$store.state.selectedAdults;
        const rooms = this.selectedRooms || 1; // Use 1 room as a default
        const municipality = this.selectedMunicipality;

        // Format the check-in and check-out dates into 'YYYY-MM-DD' format
        const startDateObj = new Date(startDate); // Create a new Date object from startDate
        startDateObj.setDate(startDateObj.getDate() + 1); // Add one day

        const formattedStartDate = startDateObj.toISOString().split("T")[0]; // Format to YYYY-MM-DD

        const endDateObj = new Date(endDate); // Create a new Date object from startDate
        endDateObj.setDate(endDateObj.getDate() + 1); // Add one day

        const formattedEndDate = endDateObj.toISOString().split("T")[0]; // Format to YYYY-MM-DD

        // Prepare the request parameters
        const hotelParams = {
          cityCode: toLocation,
          radius: 20, // Always 20 as per your description
          radiusUnit: "KM",
          adults,
          checkInDate: formattedStartDate,
          checkOutDate: formattedEndDate,
          roomQuantity: rooms,
          currency: "ZAR",
          paymentPolicy: "NONE",
          municipality,
        };

        // Fetch available hotels
        const hotels = await MyHolidayService.getHotelOffers(hotelParams);

        // Save hotels to Vuex store or handle it as per your logic
        this.$store.dispatch("saveAvailableHotels", hotels.data);
      } catch (error) {
        console.error("Failed to fetch hotel offers:", error);
      }
    },
    async getAvailableItinerary() {
      try {
        const { startDate, endDate } = this.$store.getters.selectedDates;

        // Format the check-in and check-out dates into 'YYYY-MM-DD' format
        const startDateObj = new Date(startDate); // Create a new Date object from startDate
        startDateObj.setDate(startDateObj.getDate() + 1); // Add one day

        const formattedStartDate = startDateObj.toISOString().split("T")[0]; // Format to YYYY-MM-DD

        const endDateObj = new Date(endDate); // Create a new Date object from endDate
        endDateObj.setDate(endDateObj.getDate() + 1); // Add one day

        const formattedEndDate = endDateObj.toISOString().split("T")[0]; // Format to YYYY-MM-DD

        const municipality = this.selectedMunicipality;

        // Join the interests array with ", " (comma and space) to create the desired format
        const interests = this.allInterests.join(", ");

        // Prepare the request parameters
        const params = {
          prompt: `destination: ${municipality} travel dates: ${formattedStartDate} - ${formattedEndDate} interests: ${interests}`,
        };

        const itinerary = await MyHolidayService.getAvailableItinerary(params);

        this.$store.dispatch("saveAvailableItinerary", itinerary.data);
      } catch (error) {
        console.error("Failed to fetch itinerary:", error);
      }
    },
    async getUserInterests() {
      // Fetch the phone number (msisdn) from the Vuex store
      const phoneNumber = this.$store.getters.msisdn;

      if (phoneNumber) {
        try {
          // Fetch the user interests using the phone number
          const userInterests = await MyHolidayService.getUserInterests(
            phoneNumber,
            "myHoliday"
          );

          // Map the response to get an array of interests
          const interestsArray = userInterests.data.recordset.map(
            (item) => item.Interest
          );

          this.allInterests = interestsArray;

          this.$store.dispatch("saveUserInterests", interestsArray);

          // Commit the interests to the store
        } catch (error) {
          console.error("Failed to fetch user interests:", error);
        }
      } else {
        console.error("Phone number not found in the store.");
      }
    },
    async upsertUserInterests(interests) {
      // Fetch the phone number (msisdn) from the Vuex store
      const phoneNumber = this.$store.getters.msisdn;

      try {
        await MyHolidayService.upsertUserInterests(
          phoneNumber,
          "myHoliday",
          interests
        );
      } catch (error) {
        console.error("Failed to upsert user interests:", error);
      }
    },
    applyChoices() {
      // Call the getFlightOffers method
      this.getFlightOffers();
      this.getHotelOffers();
      if (this.sendCustomItinerary) {
        this.getAvailableItinerary();
        this.$store.dispatch("saveSendCustomItinerary", true);
      }
      this.$router.push("/available-flights");
    },
  },
  mounted() {
    this.username = this.$store.getters.username;
    this.$store.commit("clearGuests", "clearDates", "clearFlights");
    this.getUserInterests();
  },
};
</script>

<style scoped>
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  margin-bottom: 5rem;
}

.greeting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.75rem;
  width: 100%;
  margin-left: 0.75rem;
}

.name {
  color: var(--vt-c-blue-secondary);
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.welcome {
  color: var(--color-text-secondary);
  font-size: 0.75rem;
  margin-top: 0;
}

.base-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-form-background);
  padding: 0.75rem;
  box-sizing: border-box;
  border-radius: 0.75rem;
  margin: 0;
}

.info-pickers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}

.picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 0;
  flex-grow: 1;
  box-sizing: border-box;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--vt-c-white);
  width: 100%;
  height: 2.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.icon {
  width: 1rem;
  height: auto;
  margin-right: 0.3rem;
}

.label {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--color-text-primary);
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--vt-c-blue-primary);
  width: 100%;
  color: var(--vt-c-white);
  border-radius: 0 0 0.5rem 0.5rem;
}

.chosen-info {
  font-size: 0.6rem;
  font-weight: 600;
}

.airport-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.search-destinations {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.search-logo {
  width: 2rem;
  margin-right: 0.5rem;
}

.search-box {
  display: flex;
  align-items: center;
  background-color: var(--vt-c-white);
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  width: 100%;
  box-sizing: border-box;
  height: 2.5rem;
  position: relative;
}

.search-input {
  border: none;
  outline: none;
  font-size: 0.75rem;
  color: var(--color-text-secondary);
  background-color: transparent;
  flex-grow: 1;
  padding-right: 2rem;
}

.search-input::placeholder {
  color: var(--color-form-placeholder);
  font-family: "Sora";
}

.search-icon {
  color: var(--color-text-primary);
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: auto;
  cursor: pointer;
}

.section-title {
  align-self: start;
  font-size: 0.9rem;
  margin: 1.5rem 0 0.5rem 0.75rem;
  color: var(--color-text-primary);
}

.no-interests {
  text-align: center;
  font-size: 0.75rem;
  color: var(--color-text-secondary);
  margin-top: -0.5rem;
}

.interests-section {
  background-color: var(--color-form-background);
  padding: 0.75rem;
  border-radius: 0.75rem;
  width: 100%;
  box-sizing: border-box;
}

.interests-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
}

.interest-item {
  background-color: var(--vt-c-white);
  border-radius: 0.5rem;
  padding: 0.3rem 0.5rem;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text-primary);
}

.remove-interest {
  background-color: var(--vt-c-white);
  font-size: 0.75rem;
  color: var(--color-text-primary);
  margin: 0;
  padding: 0 0 0 0.7rem;
}

.more-interests {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  background-color: var(--vt-c-white);
  border-radius: 0.5rem;
  color: var(--color-text-primary);
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
  margin-top: 0.75rem;
}

button {
  border: none;
  cursor: pointer;
}

.save-btn,
.add-new-btn {
  flex: 1;
  padding: 0.75rem;
  border-radius: 0.75rem;
}

.save-btn {
  background-color: var(--color-button-primary);
  color: var(--vt-c-white);
}

.add-new-btn {
  background-color: var(--vt-c-blue-primary);
  color: var(--vt-c-white);
}

.popular-destinations-section {
  width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.popular-destinations-section::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.destinations-list {
  display: flex;
  gap: 1rem;
  width: max-content; /* Ensure the container expands based on content */
}

.destination-tile {
  position: relative;
  min-width: 8rem; /* Adjust the size to make the tiles square */
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
}

.destination-image {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
}

.destination-name {
  position: absolute;
  bottom: 0; /* Stick to the bottom of the tile */
  left: 0.5rem;
  color: white;
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
}

.popular-hotels-section {
  width: 100%;
}

.hotel-tile {
  display: flex;
  position: relative; /* This is key for the absolute positioning of hotel-info */
  width: 100%;
  border-radius: 0.75rem;
  height: 8rem;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
  margin-bottom: 0.75rem;
}

.hotel-image {
  width: 100%;
  height: 12rem; /* Increase height to ensure good space for the overlay */
  object-fit: cover;
}

.hotel-info {
  position: absolute; /* Make this position absolute */
  bottom: 0; /* Align to the bottom of the image */
  left: 0;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(3, 34, 58, 0),
    /* More transparent at the top */ rgba(3, 34, 58, 1)
      /* Less transparent at the bottom */
  );
  color: white; /* Ensure text is white for contrast */
  padding: 0 0.75rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.hotel-location {
  font-size: 0.5rem;
  background-color: var(--vt-c-blue-primary);
  color: var(--vt-c-white);
  padding: 0.3rem 0.75rem;
  border-radius: 0 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -0.5rem;
  left: 1rem;
}

.hotel-rating {
  display: flex;
  margin: 0.75rem 0 -0.5rem 0;
  padding: 0;
  font-size: 0.65rem;
}

.star-icon {
  color: #ffd700;
  margin-right: 0.2rem;
}

.hotel-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.hotel-name {
  font-size: 0.75rem;
  color: var(--vt-c-white);
}

.hotel-price {
  font-size: 0.75rem;
  color: var(--vt-c-white);
  align-self: end;
  font-weight: 800;
}

.location-search-container,
.date-picker-container,
.room-picker-container,
.interest-picker-container {
  z-index: 1000;
  position: absolute;
  width: 100%;
}

.selected-destination {
  font-size: 0.75rem;
}

.placeholder-text {
  color: var(--color-form-placeholder);
}

.selected-text {
  color: var(--color-text-primary);
}

.search-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.btn-search {
  background-color: var(--vt-c-blue-secondary);
  color: var(--vt-c-white);
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 1rem 2rem;
  font-size: 0.75rem;
  font: "Sora";
  font-weight: 600;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

/* Create the custom checkbox look */
.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: self-start;
  margin-top: 1rem;
}

.checkbox-container .checkmark {
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid var(--color-form-label); /* Default unchecked border color */
  background-color: var(--vt-c-white); /* Default unchecked background */
  border-radius: 4px; /* Rounded corners */
  position: relative;
  display: inline-block;
}

/* Change the color when the checkbox is checked */
.checkbox-container input[type="checkbox"]:checked + .checkmark {
  background-color: var(--color-button-primary); /* Checked background color */
  border-color: var(--color-button-primary); /* Checked border color */
}

/* Add a checkmark icon when the box is checked */
.checkbox-container input[type="checkbox"]:checked + .checkmark::after {
  content: "";
  position: absolute;
  left: 0.23rem;
  top: 0.07rem;
  width: 0.2rem;
  height: 0.4rem;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Optional: Style the label text */
.checkbox-label {
  margin-left: 8px;
  font-size: 0.75rem;
}

/* Change the hover effect */
.checkbox-container:hover .checkmark {
  border-color: #888; /* Slightly darker on hover */
}

.error-message,
.itinerary-error-message {
  color: red;
  font-size: 0.75rem;
  margin-top: 1rem;
  text-align: center;
  margin-bottom: 0;
}
</style>
