<template>
  <div class="available-itinerary-container">
    <div class="header-navigation" @click="goHome">
      <font-awesome-icon icon="fa-solid fa-angle-left" class="close-icon" />
      <p class="back">Home</p>
    </div>
    <div class="itinerary">
      <div class="itinerary-navigation">
        <p class="itinerary-navigation-heading">
          Itinerary for {{ selectedMunicipality }}
        </p>
        <!-- <div class="download-button">
          <font-awesome-icon icon="fa-solid fa-download" />
        </div> -->
      </div>
    </div>
    <div class="itinerary-list">
      <div v-for="(day, index) in visibleDaysWithDivider" :key="index">
        <itinerary-overview :day="day" />
        <!-- Show divider if `day.showDivider` is true -->
        <div v-if="day.showDivider" class="divider"></div>
      </div>
      <p class="view-toggle" @click="toggleDays" v-if="sortedDays.length > 2">
        {{ showAllDays ? "Show Less" : "View All" }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ItineraryOverview from "../components/itinerary/ItineraryOverview.vue";

export default {
  components: {
    ItineraryOverview,
  },
  data() {
    return {
      showAllDays: false, // Controls "View All" or "Show Less"
    };
  },
  computed: {
    ...mapGetters(["availableItinerary", "selectedMunicipality"]), // Add selectedMunicipality to the getters
    visibleDays() {
      // Show first 2 days unless "View All" is clicked
      return this.sortedDays.slice(
        0,
        this.showAllDays ? this.sortedDays.length : 2
      );
    },
    sortedDays() {
      // Since the day is already a number, simply sort by the `day` field
      return this.availableItinerary.slice().sort((a, b) => a.day - b.day);
    },
    visibleDaysWithDivider() {
      // Create a new array with a 'showDivider' property added for each day except the last one
      return this.visibleDays.map((day, index, array) => {
        return {
          ...day,
          showDivider: index < array.length - 1, // Add divider unless it's the last day
        };
      });
    },
  },
  methods: {
    toggleDays() {
      this.showAllDays = !this.showAllDays;
    },
    goHome() {
      this.$store.commit("clearHotels");
      this.$store.commit("clearFlights");
      this.$store.commit("clearItinerary");
      this.$router.push("/home");
    },
  },
};
</script>

<style scoped>
.available-itinerary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  margin-bottom: 5rem;
}

.header-navigation {
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.75rem;
  margin-top: 1rem;
  font-weight: 700;
  color: var(--color-text-primary);
}

.itinerary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.itinerary-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-self: flex-start;
}

.itinerary-navigation-heading {
  margin: 1rem 0 1rem 0.75rem;
}

.download-button {
  font-size: 1rem;
  color: var(--color-text-primary);
}

.itinerary-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  width: 100%;
  gap: 0.75rem;
}

.view-toggle {
  color: var(--color-text-primary);
  cursor: pointer;
  text-align: center;
  font-size: 0.75rem;
}

.divider {
  height: 0.1rem;
  background-color: var(--color-form-divider);
  margin-top: 1rem;
}
</style>
